import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '@material-ui/core/Tooltip';

interface FilterItemProps {
  description?: any;
  value?: any;
  selected?: boolean;
  disabled?: boolean;
  onClick: Function;
  valueType?: boolean;
  icon?: any;
  tooltip?: any;
  iconType?: boolean;
  tolltipTitle?: any;
  colorText?: any;
  colorBorderBottom?: any;
  enableBorderBottom?: any;
  padding?: any;
  borderJustWhenSelected?: any;
  spaceBtween?: any;
  hidden?:any;
}

const FilterItem: React.FC<FilterItemProps> = (props: any) => {
  return (
    <Grid
      item
      hidden={props.hidden}
    >
      <div
        style = {props.spaceBtween ? {marginLeft: props.spaceBtween, marginRight: props.spaceBtween} : {}}
      >
        <Tooltip
          arrow
          title={
            <React.Fragment>
              <h5 hidden={props.tolltipTitle} style={{ margin: 2, padding: 0 }}>
                Filtro: {props.description}
              </h5>
              <h5 hidden={!props.tolltipTitle} style={{ margin: 2, padding: 0 }}>
                {props.tolltipTitle}
              </h5>
              <p style={{ margin: 2, padding: 0, lineHeight: "1.2em" }}>
                {props.tooltip}
              </p>
            </React.Fragment>
          }
        >
          <div>
            <Hidden smUp>
              <div
                onClick={props.onClick}
                style={{
                  cursor: "pointer",
                  padding: !props.borderJustWhenSelected ?
                    props.padding >= 0 ? props.padding : "10px 5px " + (props.selected ? "13px" : "16px") 
                    : props.padding >= 0 ? props.padding : "10px 5px " + (props.selected ? "10px" : "16px"),
                  color: props.colorText ? props.colorText : props.selected ? (props.colorBorderBottom ? props.colorBorderBottom : "rgb(245,187,54)") : props.disabled ? "rgba(255, 255, 255, 0.5)" : "rgba(255, 255, 255, 1)",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  userSelect: "none",
                  borderBottom: !props.borderJustWhenSelected ?
                    props.enableBorderBottom || props.selected ? (props.selected && props.enableBorderBottom ? "6px" : "3px") + " solid " + (props.colorBorderBottom ? props.colorBorderBottom : "#F5BB36") : "none"
                    : props.enableBorderBottom || props.selected ? "6px solid " + (props.colorBorderBottom ? props.colorBorderBottom : "#F5BB36") : "none",
                }}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Grid item hidden={!props.icon}>
                    <div style={{
                      fontSize: "10px",
                      padding: "0",
                      margin: "0"
                    }}>
                      {props.icon}
                    </div>
                  </Grid>
                  <Grid item hidden={!props.description}>
                    <p style={{
                      fontSize: props.iconType ? "10px" : "10px",
                      padding: "0",
                      margin: "0"
                    }}>
                      {props.description}
                    </p>
                  </Grid>
                  <Grid hidden={!props.valueType} item>
                    <p style={{
                      fontSize: "10px",
                      padding: "0",
                      margin: "0"
                    }}>
                      {props.value}
                    </p>
                  </Grid>
                </Grid>
              </div>
            </Hidden>
            <Hidden xsDown >
              <div
                onClick={props.onClick}
                style={{
                  cursor: "pointer",
                  padding: !props.borderJustWhenSelected ?
                    props.padding >= 0 ? props.padding : "10px 20px " + (props.selected ? "10px" : "13px")
                    : props.padding >= 0 ? props.padding : "10px 20px " + (props.selected ? "7px" : "13px"),
                  color: props.colorText ? props.colorText : props.selected ? (props.colorBorderBottom ? props.colorBorderBottom : "rgb(245,187,54)") : props.disabled ? "rgba(255, 255, 255, 0.5)" : "rgba(255, 255, 255, 1)",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  userSelect: "none",
                  borderBottom: !props.borderJustWhenSelected ?
                    props.enableBorderBottom || props.selected ? (props.selected && props.enableBorderBottom ? "6px" : "3px") + " solid " + (props.colorBorderBottom ? props.colorBorderBottom : "#F5BB36") : "none"
                    : props.enableBorderBottom || props.selected ? "6px solid " + (props.colorBorderBottom ? props.colorBorderBottom : "#F5BB36") : "none"
                }}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Grid item hidden={!props.icon}>
                    <div style={{
                      fontSize: "15px",
                      padding: "0",
                      margin: "0"
                    }}>
                      {props.icon}
                    </div>
                  </Grid>
                  <Grid item hidden={!props.description}>
                    <p style={{
                      fontSize: props.iconType ? "10px" : "15px",
                      padding: "0",
                      margin: "0"
                    }}>
                      {props.description}
                    </p>
                  </Grid>
                  <Grid hidden={!props.valueType} item>
                    <p style={{
                      fontSize: "15px",
                      padding: "0",
                      margin: "0"
                    }}>
                      {props.value}
                    </p>
                  </Grid>
                </Grid>
              </div>
            </Hidden>
          </div>
        </Tooltip>
      </div>
    </Grid>
  )
}

export default FilterItem