import { CREATE_EVENT_LISTENER } from '../actions/actionTypes';
const initialState = {
  created: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_EVENT_LISTENER:
      return {
        ...state,
        created: action.created
      };
    default:
      return state;
  }
};