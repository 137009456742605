import React, { useState } from 'react';
import './ShowMoreButton.css';
import {
    IonModal, IonHeader, IonButton, IonButtons, IonToolbar, IonContent, IonTitle
} from '@ionic/react';

interface ShowMoreButtonProps {
    content: string;
    limit: number;
    title?: string;
    somenteLimitar?: boolean;
}

const ShowMoreButton: React.FC<ShowMoreButtonProps> = (props) => {
    const [showModal, setShowModal] = useState(false);

    if (props.content.length <= props.limit) {
        return <span>{props.content}</span>
    }
    
    const toShow = props.content.substring(0, props.limit) + "...";

    return <span>
        {toShow}
        <button className='btn-ver-mais' onClick={() => setShowModal(true)} hidden={props.somenteLimitar}>Ver mais</button>

        <IonModal
            cssClass="modalProCad"
            isOpen={showModal}
            swipeToClose={true}
            onDidDismiss={() => {
                setShowModal(false)
            }}
        >
            <IonHeader translucent>
                <IonToolbar className='bakground-white'>
                    <IonTitle>{props.title}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => setShowModal(false)}>Fechar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className='div-modal'>
                    {props.content}
                </div>
            </IonContent>
        </IonModal>
    </span>
}

export default ShowMoreButton;