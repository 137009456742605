class FiltrosJsonProvider {
    static getJsonFiltrosPedidos() {
      return [
        {
          field: "id",
          type: "int",
          comparison: "eq",
          connector: "OR",
        },
        {
          field: "descricao",
          type: "string",
          comparison: "cn",
          connector: "OR",
          searchByWordKeys: true
        },
        {
          field: "observacao",
          type: "string",
          comparison: "cn",
          connector: "OR",
          searchByWordKeys: true
        },
        {
          grupo: [
            {
              field: "dataInicioDigitacao",
              type: "datetime",
              comparison: "le",
              connector: "AND",
            },
            {
              grupo: [
                {
                  field: "dataTerminoDigitacao",
                  type: "datetime",
                  comparison: "ge",
                  connector: "OR",
                },
                {
                  field: "dataTerminoDigitacao",
                  value: "null",
                  type: "datetime",
                  comparison: "eq",
                  connector: "OR",
                },
              ],
              connector: "AND",
            },
          ],
          connector: "OR",
        },
        {
          field: "dataPedido",
          type: "date",
          comparison: "eq",
          connector: "OR",
        },
      ];
    }
  }
  
  export default FiltrosJsonProvider;