import React, { useEffect, useState } from 'react';
import {
  IonHeader, IonContent, IonToolbar, IonButtons, IonToast, IonButton,
  IonGrid, IonRow, IonCol, IonIcon, IonModal, IonTitle
} from '@ionic/react';
import { Plugins } from '@capacitor/core';
import { trash, addCircle, attachOutline } from 'ionicons/icons';
import { FormControl, Popover } from '@material-ui/core';
import './PedidoProdutoCad.css';
import FilterContainer from '../../../../components/filterContainer/filterContainer';
import FilterItem from '../../../../components/filterContainer/filterItem';
import ajax from '../../../../assets/utils/ajax';
import ConfigProvider from '../../../../assets/utils/configProvider';
import MyInput from '../../../../components/form/MyInput';
import Hidden from '@material-ui/core/Hidden';
import {
  G3ArrowBackEfeitoIcon,
  G3ArrowBackEfeitoDisableIcon,
  G3ArrowForwardEfeitoIcon,
  G3ArrowForwardEfeitoDisableIcon,
  G3FecharEfeitoIcon
} from '../../../../assets/utils/g3icons';
import PedidoProdutoFilialCon from './filial/PedidoProdutoFilialCon';
import AnexoProdutoCon from './anexo/AnexoProdutoCon';
import LoadingGif from '../../../../components/loadingGif/LoadingGif';

const { SecureStoragePlugin } = Plugins;

interface ProdutoCadProps {
  itemData: any;
  idPedidoExtra: any;
  idUsuarioPortal: any;
  cadastroPedido: boolean;
  usuarioCriador: boolean;
  pedidoDisabled: boolean;
  propsRouter: any;
  onSetShowModal: any;
}

interface Registro {
  id: any,
  idEmpresa: any;
  descricao: any;
  barras: any;
  precoCompra: any;
  precoVenda: any;
  observacao?: any;
  observacaoPortal?: any;
  idUsuarioPortal?: any;
  readOnly?: boolean;
}

const novoRegistro = {
  id: null,
  idEmpresa: null,
  descricao: "",
  barras: "",
  precoCompra: 0,
  precoVenda: 0,
  observacao: "",
  observacaoPortal: ""
};

let salvou = false;
const msgLoadingDeafult = "Carregando produto...";

const PedidoProdutoCad: React.FC<ProdutoCadProps> = (props) => {
  const [showModalAnexo, setShowModalAnexo] = useState(false);
  const [showAlert, setShowAlert] = useState<object>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showLoadingMsg, setShowLoadingMsg] = useState<string>(msgLoadingDeafult);
  const [showPopover, setShowPopover] = useState(false);
  const [registro, setRegistro] = useState<Registro>(novoRegistro);
  const [disableAnterior, setDisableAnterior] = useState<boolean>(false);
  const [disableProximo, setDisableProximo] = useState<boolean>(false);
  const [focusOnRequestEnd, setFocusOnRequestEnd] = useState<boolean>(false);
  const formRef = React.useRef<any>(null);

  useEffect(() => {
    findProdutoPedido();
  }, [props.itemData]);

  useEffect(() => {
    if (showModalAnexo == true) {
      window.location.hash = "#produtoAnexoModal"
    } else {
      window.history.pushState("", document.title, window.location.pathname + window.location.search);
    }
  }, [showModalAnexo])


  const findProdutoPedido = (anterior?: any, proximo?: any, naoMostraMsg?: boolean, registroAux?: any) => {
    let reg = naoMostraMsg ? registroAux : registro;

    const isNovoReg = reg.id == null && props.itemData.id == null;
    if (isNovoReg) {
      btnNovo();
      return;
    }

    if (!naoMostraMsg) setShowLoading(true);

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/produtos/cadastro/exists/app',
      data: {
        registro: {
          id: reg.id != null ? reg.id : props.itemData.id,
          idPedidoExtra: props.idPedidoExtra,
          anterior: anterior ? 1 : null,
          proximo: proximo ? 1 : null,
        }
      },
      fnSetShowAlert: !naoMostraMsg ? setShowAlert : () => { },
      beforeMsgSuccessTrue: function (response: any) {
        setRegistro({
          id: response.registro.id,
          idEmpresa: response.registro.idEmpresa,
          descricao: response.registro.descricao,
          barras: response.registro.barras,
          precoCompra: response.registro.precoCompra,
          precoVenda: response.registro.precoVenda,
          observacao: response.registro.observacao,
          observacaoPortal: response.registro.observacaoPortal
        });
        setDisableAnterior(!response.registro.existeAnterior);
        setDisableProximo(!response.registro.existeProximo);

        if (response.responseCallback) {
          response.responseCallback()
        }
        if (!naoMostraMsg) setShowLoading(false);
      },
      beforeMsgSuccessFalse: function (response: any) {
        if (!naoMostraMsg) setShowLoading(false);
      },
      beforeMsgSuccessFailure: function (response: any) {
        if (!naoMostraMsg) setShowLoading(false);
      }
    });
  }

  const saveProduto = (value: any, callback: any) => {
    let registroAux = { ...registro, ...value };
    registroAux.id = registroAux.id == 0 ? null : registroAux.id;
    registroAux.idPedidoExtra = props.idPedidoExtra;
    formRef.current = registroAux;

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/produtos/cadastro/' + (registroAux.id ? "update/app" : "save/app"),
      data: {
        registro: registroAux
      },
      fnSetShowAlert: setShowAlert,
      fnGetRegistro: () => {
        return { ...formRef.current, ...value }
      },
      beforeMsgSuccessTrue: function (response: any) {
        formRef.current = response.registro
        setRegistro(response.registro);

        // //aqui salvar na sessão a descrição do ultimo produto salvo
        SecureStoragePlugin.set({ key: "ultimaDescricaoProduto", value: encodeURIComponent(response.registro.descricao) });

        salvou = true;

        if (callback) callback(true);
        if (response.responseCallback) {
          response.responseCallback()
        }

        if (registroAux.id == null) {
          findProdutoPedido(null, null, true, response.registro);
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        if (callback) callback(false);
        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFailure: function (response: any) {
        if (callback) callback(false);
        if (response.responseCallback) {
          response.responseCallback()
        }
      }
    });
  }

  const onExcluirProduto = () => {
    if (registro.id === null) return;

    setShowLoadingMsg("Removendo produto...");

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/produtos/cadastro/delete/app',
      data: {
        registros: [{
          id: registro.id
        }]
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setRegistro(novoRegistro);
        setShowLoadingMsg(msgLoadingDeafult);
        setShowAlert({
          isOpen: true,
          duration: 4000,
          color: 'success',
          header: 'Sucesso',
          message: "Produto removido com sucesso!",
          buttons: ['OK'],
          onDidDismiss: () => {
            setShowAlert({ isOpen: false });
          }
        });

        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      },
      beforeMsgSuccessFailure: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      }
    });
  }

  const btnNovo = () => {
    setShowLoading(true);
    setFocusOnRequestEnd(false);

    SecureStoragePlugin.get({ key: 'ultimaDescricaoProduto' })
      .then((ultimaDescricaoProduto: any) => {
        novoRegistro.descricao = decodeURIComponent(ultimaDescricaoProduto.value);
        setRegistro(novoRegistro);
      })
      .catch((error: any) => {
        setRegistro(novoRegistro);
      });

    setDisableAnterior(true);
    setDisableProximo(true);
    setTimeout(() => {
      setShowLoading(false);
      setFocusOnRequestEnd(true);
    }, 500);
  }

  return (
    <>
      <IonHeader className="ionGridViewport-border-red">
        <IonGrid className="ionGridViewport">
          <IonRow>
            <IonCol className="ionColViewport" size={"4"}>
              <IonToolbar>
                <IonButtons slot="start">
                </IonButtons>
              </IonToolbar>
            </IonCol>

            <IonCol className="ionColViewport" style={{ height: "inherit" }} size={"4"}>
              <IonToolbar>
                <IonButtons>
                  <div className="container">
                    <FilterContainer>
                      <FilterItem
                        iconType
                        disabled={disableAnterior}
                        onClick={(e: any) => {
                          if (disableAnterior) return;

                          e.preventDefault();
                          findProdutoPedido(1);
                        }}
                        icon={<div style={{}}> {disableAnterior ? <G3ArrowBackEfeitoDisableIcon className="myIcon" /> : <G3ArrowBackEfeitoIcon className="myIcon" />} </div>}
                        padding={0}
                        tooltip={'Clique para visualizar o produto anterior.'}
                        tolltipTitle={'Navegar: Produto anterior'}
                      />

                      <Hidden smUp>
                        <div style={{ width: '20px', height: "100%" }}></div>
                      </Hidden>

                      <FilterItem
                        iconType
                        disabled={disableProximo}
                        onClick={(e: any) => {
                          if (disableProximo) return;

                          e.preventDefault();
                          findProdutoPedido(null, 1);
                        }}
                        icon={<div style={{}}> {disableProximo ? <G3ArrowForwardEfeitoDisableIcon className="myIcon" /> : <G3ArrowForwardEfeitoIcon className="myIcon" />}</div>}
                        padding={0}
                        tooltip={'Clique para visualizar o próximo produto.'}
                        tolltipTitle={'Navegar: Próximo produto'}
                      />
                    </FilterContainer>
                  </div>
                </IonButtons>
              </IonToolbar>
            </IonCol>

            <IonCol style={{ height: "inherit" }} className="ionColViewport">
              <IonToolbar>
                <IonButtons slot="end">
                  <FilterContainer>
                    <FilterItem
                      iconType
                      onClick={() => {
                        props.onSetShowModal(false)
                        salvou = false;
                      }}
                      icon={<><div style={{ marginLeft: '8px', marginRight: '8px' }}><G3FecharEfeitoIcon className="myIcon" /></div></>}
                      colorText="#404237"
                      padding={0}
                      tolltipTitle={'Ação: Fechar tela'}
                    />
                  </FilterContainer>
                </IonButtons>
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>

      <IonContent>
        <div className="d-table w-100">
          <div className="d-table-cell tar">
            <IonButton
              hidden={!props.usuarioCriador || props.pedidoDisabled}
              className='btn-novo-pedido'
              onClick={() => btnNovo()}
            >
              NOVO PRODUTO
              <IonIcon slot="start" size='large' icon={addCircle} />
            </IonButton>
          </div>
        </div>

        <IonToast
          isOpen={false}
          cssClass='my-custom-class'
          mode='ios'
          {...showAlert}
        />

        <LoadingGif show={showLoading} msg={showLoadingMsg} />

        {!showLoading ? <FormControl
          fullWidth
          variant="outlined"
          required
        >
          <form style={{ margin: 15 }}>
            <>
              <MyInput
                typeComponent="text"
                value={registro.descricao}
                onSave={saveProduto}
                focusOnRequestEnd={focusOnRequestEnd}
                required={true}
                propsInput={{
                  name: "descricao",
                  placeholder: "Descrição do produto",
                  autoFocus: true,
                  required: true,
                  disabled: !props.usuarioCriador || props.pedidoDisabled
                }}
              />

              <MyInput
                typeComponent="text"
                value={registro.barras}
                onSave={saveProduto}
                propsInput={{
                  name: "barras",
                  placeholder: "Código de barras",
                  disabled: !props.usuarioCriador || props.pedidoDisabled
                }}
                width="50%"
              />

              {!props.usuarioCriador ?
                <MyInput
                  typeComponent="text"
                  value={registro.precoCompra}
                  type="number"
                  decimal="4"
                  currency={true}
                  width="50%"
                  onSave={saveProduto}
                  propsInput={{
                    name: "precoCompra",
                    placeholder: "Preço Compra",
                    disabled: true
                  }}
                />
                : ""}

              <MyInput
                typeComponent="text"
                value={registro.precoVenda}
                focusOnRequestEnd={focusOnRequestEnd}
                type="number"
                decimal="4"
                currency={true}
                width="50%"
                onSave={saveProduto}
                propsInput={{
                  name: "precoVenda",
                  autoFocus: !props.usuarioCriador || props.pedidoDisabled,
                  placeholder: "Preço Venda",
                  disabled: !props.usuarioCriador || props.pedidoDisabled
                }}
              />

              <MyInput
                typeComponent="text"
                value={registro.observacao}
                propsInput={{
                  name: "observacao",
                  placeholder: "Observação do comprador",
                  multiline: true,
                  rows: 3,
                  disabled: true
                }}
              />

              <MyInput
                typeComponent="text"
                value={registro.observacaoPortal}
                onSave={saveProduto}
                propsInput={{
                  name: "observacaoPortal",
                  placeholder: "Sua Observação",
                  multiline: true,
                  rows: 3,
                  disabled: !props.usuarioCriador || props.pedidoDisabled
                }}
              />
            </>
          </form>
        </FormControl> : ''}

        {!showLoading && registro.id != null ? <PedidoProdutoFilialCon
          idPedidoExtra={props.idPedidoExtra}
          idPedidoExtraProduto={registro.id}
          descricaoProduto={registro.descricao}
          onSetFocusOnRequestEnd={setFocusOnRequestEnd}
          pedidoDisabled={props.pedidoDisabled}
          propsRouter={props.propsRouter}
        /> : ""}

        <IonToast
          isOpen={false}
          cssClass='my-custom-class'
          mode='ios'
          {...showAlert}
        />

        <IonModal
          cssClass="modalProCad"
          backdropDismiss={false}
          swipeToClose={false}
          isOpen={showModalAnexo}
          onDidDismiss={() => setShowModalAnexo(false)}
        >
          {showModalAnexo ?
            <AnexoProdutoCon
              idProdutoExtra={registro.id}
              idUsuarioPortal={props.idUsuarioPortal}
              propsRouter={props.propsRouter}
              disableSave={!props.usuarioCriador || props.pedidoDisabled}
              onSetShowModalAnexo={(cond: boolean, refresh: boolean) => {
                setShowModalAnexo(cond);
              }}
            /> : ""}
        </IonModal>

        <div style={{ marginBottom: "100px" }} />
        <div
          style={{ zIndex: 2, position: "fixed", bottom: "0px", width: "100%", textAlign: "center" }}
        >
          <IonToolbar>
            <IonGrid>
              <IonRow>
                <IonCol size='12'>
                  <div className='div-btn-toolbar-bottoms'>
                    <div className='div-btn-toolbar-bottom' onClick={() => {
                      setShowModalAnexo(true);
                    }}>
                      <IonIcon className='icon-btn-toolbar-bottom' slot="start" icon={attachOutline} />
                      <p>ANEXOS</p>
                    </div>

                    <div className='div-btn-toolbar-bottom' onClick={() => setShowPopover(true)}
                      hidden={!props.usuarioCriador || props.pedidoDisabled}>
                      <IonIcon className='icon-btn-toolbar-bottom' slot="start" icon={trash} />
                      <p>REMOVER PRODUTO</p>
                    </div>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </div>

        <div className="container">
          <Popover
            open={showPopover}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            // mode="ios"
            onClose={e => {
              setShowPopover(false)
            }}
          >
            <div className="myPopover">
              <p>Deseja realmente remover este produto?</p>
              <IonButton
                color="success"
                onClick={() => {
                  onExcluirProduto();
                  setShowPopover(false);
                }}
                size="small"
              >
                Remover
              </IonButton>
              <IonButton
                color="danger"
                onClick={() => setShowPopover(false)}
                size="small"
              >
                Cancelar
              </IonButton>
            </div>
          </Popover>
        </div>
      </IonContent>
    </>
  );
};

export default PedidoProdutoCad;
