import React, { useState, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonSpinner } from '@ionic/react';
import 'capacitor-secure-storage-plugin';
import { Plugins } from '@capacitor/core';
import { IonReactRouter } from '@ionic/react-router';


import Login from './pages/login/Login';
import Viewport from './pages/viewport/Viewport';
import Construcao from './pages/contrucao/construcao';
import AprovacaoOrdemCompra from './pages/aprovacaoOrdemCompra/AprovacaoOrdemCompra';
import AprovacaoPedidoExtra from './pages/aprovacaoPedidoExtra/AprovacaoPedidoExtra';
import PedidoCon from './pages/viewport/pedido/PedidoCon';
import PedidoProdutoCon from './pages/viewport/pedido/produto/PedidoProdutoCon';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from "date-fns/locale/pt-BR";

import ajax from './assets/utils/ajax';
import VideoTutorial from './video/video';

import Fab from '@material-ui/core/Fab';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import ConfigProvider from './assets/utils/configProvider';

/* Theme variables */
import './theme/variables.css';
// import { render } from '@testing-library/react';

const fabHelp = (logado: any) => {
  if (window.location.pathname.indexOf("/ordemCompra") === -1 && window.location.pathname != "/documentacao" && !logado) {
    return (
      <Fab
        style={{
          position: 'absolute',
          right: '10px',
          bottom: logado ? '70px' : '10px',
          zIndex: 90000,
          backgroundColor: '#e53b3e',
          color: '#FFFFFF'
        }}
        aria-label="edit"
        onClick={() => {
          (window as any).open('/documentacao', '_blank').focus();
        }}
      >
        <LiveHelpIcon />
      </Fab>
    )
  }

}

const { SecureStoragePlugin } = Plugins;

interface AppProps {
  hideLoader: any
}
const App: React.FC<AppProps> = ({ hideLoader }) => {
  const [isLogged, setIsLogged] = useState<boolean>();

  const verificaToken = (token: any) => {
    ajax({
      url: new ConfigProvider().getConfig("baseURLG3Auth") + '/token/valida/portal',
      data: {
        token: token.value,
        tipoAplicacao: new ConfigProvider().getConfig("tipoAplicacao")
      },
      beforeMsgSuccessFalse: function (response: any) {
        if (response.errors) {
          if (response.errors.length > 0) {
            setIsLogged(false)
          } else {
            setIsLogged(true)
          }
        } else {
          setIsLogged(true)
        }
      }
    });
  }

  useEffect(() => {
    const { SplashScreen } = Plugins
    SplashScreen.hide();
  }, [])

  useEffect(() => {

    async function getSession() {
      try {
        await SecureStoragePlugin.get({ key: 'token' })
          .then((value: any) => {
            if (isLogged !== (value !== null && value != "undefined")) {
              hideLoader();
              verificaToken(value);
            }
          })
          .catch((error: any) => {
            if (isLogged || isLogged === undefined) {
              hideLoader();
              setIsLogged(false);
            }
          });
      } catch (error) {
        hideLoader();
        setIsLogged(false);
      }
    }

    if (window.location.pathname.indexOf("/ordemCompra") !== -1) {
      hideLoader();
      setIsLogged(false);
    } else {
      getSession();
    }

  }, [hideLoader, isLogged]);

  if (isLogged === undefined) {
    return (
      <div className="container">
        <IonSpinner name="crescent" />
      </div>
    )
  }
  else {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
        <>
          {fabHelp(isLogged)}
          <IonReactRouter>
            <Switch>

              <Route path="/login" render={() => {
                if (isLogged) return <Redirect to="/viewport" />
                return <Login onSetIsLogged={setIsLogged} />
              }} exact={true} />

              <Route path="/login/:email" render={(props) => {
                if (isLogged) return <Redirect to="/viewport" />
                return <Login onSetIsLogged={setIsLogged} email={props.match.params.email} />
              }} exact={true} />

              <Route path="/login/alterar/:token/:email" render={(props) => {
                return <Login onSetIsLogged={setIsLogged} welcome={true} typeWelcome={"alterar"} token={props.match.params.token} email={props.match.params.email} />
              }} exact={true} />

              <Route path="/login/criar/:token/:email" render={(props) => {
                return <Login onSetIsLogged={setIsLogged} welcome={true} typeWelcome={"criar"} token={props.match.params.token} email={props.match.params.email} />
              }} exact={true} />

              <Route path="/viewport" render={(props) => {
                return !isLogged ? <Login onSetIsLogged={setIsLogged} /> : <Viewport propsRouter={props} onSetIsLogged={setIsLogged} Component={PedidoCon} ComponentChild={null} />
              }} exact={true} />

              <Route path="/viewport/:email/pedido/:idPedidoExtra/produtos" render={(props) => {
                return !isLogged ? <Login onSetIsLogged={setIsLogged} email={props.match.params.email} /> 
                : 
                <Redirect to={"/viewport/pedido/" + props.match.params.idPedidoExtra + "/produtos"} />
              }} exact={true} />

              <Route path="/viewport/pedido/:idPedidoExtra/produtos" render={(props) => {
                return !isLogged ? <Login onSetIsLogged={setIsLogged} user={props.match.params.idPedidoExtra} /> : <Viewport propsRouter={props} onSetIsLogged={setIsLogged} Component={PedidoProdutoCon} ComponentChild={null} />
              }} exact={true} />

              <Route path="/public/ordemCompra/:idOrdemCompra/aprovacao/token/:idToken" render={(props) => { 
                return <AprovacaoOrdemCompra aprovar={true} idOrdemCompra={props.match.params.idOrdemCompra} idToken={props.match.params.idToken} />
              }} exact={true} />

              <Route path="/public/ordemCompra/:idOrdemCompra/rejeicao/token/:idToken" render={(props) => { 
                return <AprovacaoOrdemCompra aprovar={false} idOrdemCompra={props.match.params.idOrdemCompra} idToken={props.match.params.idToken} />
              }} exact={true} />

              <Route path="/public/pedidoExtra/:idRegistro/aprovacao/token/:idToken" render={(props) => { 
                return <AprovacaoPedidoExtra aprovar={true} idRegistro={props.match.params.idRegistro} idToken={props.match.params.idToken} />
              }} exact={true} />

              <Route path="/public/pedidoExtra/:idRegistro/rejeicao/token/:idToken" render={(props) => { 
                return <AprovacaoPedidoExtra aprovar={false} idRegistro={props.match.params.idRegistro} idToken={props.match.params.idToken} />
              }} exact={true} />

              <Route path="/construcao" render={(props) => {
                return <Construcao />
              }} exact={true} />

              <Route path="/documentacao" render={(props) => {
                return <VideoTutorial />
              }} exact={true} />

              <Route path="/" render={() => {
                if (!isLogged) return <Redirect to="/login" />
                return <Redirect to="/viewport" />
              }} />
            </Switch>
          </IonReactRouter>
        </>
      </MuiPickersUtilsProvider>
    )
  }
};

export default App;