import React, { useState, useEffect } from 'react';
import './PedidoProdutoFilialCon.css';
import {
  IonSearchbar, IonToast, IonModal, IonButton, IonTitle, IonSpinner
} from '@ionic/react';
import { Popover } from '@material-ui/core';
import ConfigProvider from '../../../../../assets/utils/configProvider'
import ajax from '../../../../../assets/utils/ajax';
import CardFilial from './cardFilial/CardFilial';
import { Plugins } from '@capacitor/core';
import PedidoProdutoFilialCad from './PedidoProdutoFilialCad';
import PedidoProdutoFilialResumidaCad from './PedidoProdutoFilialResumidaCad';

const { SecureStoragePlugin } = Plugins;

let page = 1;
let start = 0;
const msgLoadingDeafult = "Carregando filiais...";

interface FilialProps {
  idPedidoExtra: any;
  idPedidoExtraProduto: any;
  descricaoProduto: any;
  onSetFocusOnRequestEnd: any;
  pedidoDisabled: boolean;
  propsRouter: any;
}

const PedidoProdutoFilialCon: React.FC<FilialProps> = (props) => {
  const [showAlert, setShowAlert] = useState<object>();
  const [showModal, setShowModal] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [searchFilial, setSearchFilial] = useState<any>("");
  const [filialSelecionada, setFilialSelecionada] = useState<any>({});
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showLoadingMsg, setShowLoadingMsg] = useState<string>(msgLoadingDeafult);
  const [arrData, setArrData] = useState<any>([]);
  const [arrDataSorted, setArrDataSorted] = useState<any>([{ property: "id", direction: "ASC" }]);

  useEffect(() => {
    loadFiliais();
  }, [props.idPedidoExtra, props.idPedidoExtraProduto, arrDataSorted]);

  useEffect(() => {
    loadFiliais();
  }, [searchFilial]);

  const loadFiliais = (naoMostraMsg?: any, naoSetarFoco?: any) => {
    page = 1;
    start = 0;

    var filtrosAux: any = [{
      field: "idPedidoExtra",
      value: props.idPedidoExtra,
      type: "int",
      comparison: "eq",
      connector: "AND"
    }, {
      field: "idPedidoExtraProduto",
      value: props.idPedidoExtraProduto || 0,
      type: "int",
      comparison: "eq",
      connector: "AND"
    }];

    if (searchFilial != "") {
      filtrosAux.push({
        field: "idFilial",
        value: searchFilial,
        type: "int",
        comparison: "eq",
        connector: "AND"
      });
    }

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/produtos/filiais/consulta/load/app',
      data: {
        page: page,
        start: start,
        limit: 100000,
        filterAva: [],
        filterFix: filtrosAux,
        sort: arrDataSorted,
      },
      fnSetShowLoading: naoMostraMsg ? () => { } : setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setArrData(response.registros);
        if (response.responseCallback) response.responseCallback();
        if (!naoSetarFoco) props.onSetFocusOnRequestEnd(true);
      },
      beforeMsgSuccessFalse: function (response: any) {
        if (!naoSetarFoco) props.onSetFocusOnRequestEnd(true);
      },
      beforeMsgSuccessFailure: function (response: any) {
        if (!naoSetarFoco) props.onSetFocusOnRequestEnd(true);
      }
    });
  }

  const onLimparFilial = () => {
    if (filialSelecionada.id === null) return;

    setShowLoadingMsg("Limpando filial...");

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/produtos/filiais/cadastro/delete/app',
      data: {
        registros: [{
          id: filialSelecionada.id,
          idPedidoExtraProduto: props.idPedidoExtraProduto || 0
        }]
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
        setShowAlert({
          isOpen: true,
          duration: 4000,
          color: 'success',
          header: 'Sucesso',
          message: "As informações desta filial foram desfeitas com sucesso!",
          buttons: ['OK'],
          onDidDismiss: () => {
            setShowAlert({ isOpen: false });
          }
        });

        loadFiliais();

        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      },
      beforeMsgSuccessFailure: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      }
    });
  }

  return (
    <>
      <PedidoProdutoFilialResumidaCad
        idPedidoExtraProduto={props.idPedidoExtraProduto}
        loadFiliais={loadFiliais}
      />

      <div style={{ textAlign: "center" }}>
        <IonSpinner name="crescent" hidden={!showLoading} />
      </div>

      <IonTitle>Filiais para lançar</IonTitle>

      <IonSearchbar placeholder="Buscar filial" value={searchFilial}
        onIonChange={e => {
          let val = e.detail.value;
          val = val != null ? val.replace(/[^\d,]+/g, '') : val;
          setSearchFilial(val)

        }}
        debounce={750}
        showCancelButton="focus"></IonSearchbar>

      {!showLoading ? <CardFilial
        data={arrData}
        onSetFilialSelecionada={setFilialSelecionada}
        onSetShowModal={setShowModal}
        onSetShowPopover={setShowPopover}
        pedidoDisabled={props.pedidoDisabled}
        propsRouter={props.propsRouter}
      /> : ""}

      <IonModal
        cssClass="modalProCad"
        backdropDismiss={false}
        swipeToClose={false}
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
      >
        <PedidoProdutoFilialCad
          itemData={filialSelecionada}
          idPedidoExtraProduto={props.idPedidoExtraProduto}
          descricaoProduto={props.descricaoProduto}
          filiais={arrData}
          onSetFilialSelecionada={setFilialSelecionada}
          onLoadFiliais={loadFiliais}
          pedidoDisabled={props.pedidoDisabled}
          propsRouter={props.propsRouter}
          onSetShowModal={(cond: boolean) => {
            setShowModal(cond);
          }}
        />
      </IonModal>

      <IonToast
        isOpen={false}
        cssClass='my-custom-class'
        mode='ios'
        {...showAlert}
      />

      <div className="container">
        <Popover
          open={showPopover}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          // mode="ios"
          onClose={e => {
            setShowPopover(false)
          }}
        >
          <div className="myPopover">
            <p>Deseja realmente limpar as informações desta filial?</p>
            <IonButton
              color="success"
              onClick={() => {
                onLimparFilial();
                setShowPopover(false);
              }}
              size="small"
            >
              Remover
            </IonButton>
            <IonButton
              color="danger"
              onClick={() => setShowPopover(false)}
              size="small"
            >
              Cancelar
            </IonButton>
          </div>
        </Popover>
      </div>
    </>
  );
};

export default PedidoProdutoFilialCon;
