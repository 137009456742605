import React, { useEffect, useState } from "react";
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonButtons,
  IonToast,
  IonTitle,
  IonSpinner,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonTextarea,
  IonItem,
  IonBadge,
  IonButton,
  IonPage,
  IonMenuButton,
  IonList,
  IonMenu,
  IonSplitPane,
  IonLabel,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import { menuController } from "@ionic/core";
import "./Chat.css";
import {
  send,
  pencil,
  refreshCircleOutline,
  chevronDownOutline,
} from "ionicons/icons";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Paper, Popover } from "@material-ui/core";
import FilterContainer from "../../../../components/filterContainer/filterContainer";
import FilterItem from "../../../../components/filterContainer/filterItem";
import ajax from "../../../../assets/utils/ajax";
import ConfigProvider from "../../../../assets/utils/configProvider";
import { G3FecharEfeitoIcon } from "../../../../assets/utils/g3icons";
import { Plugins } from "@capacitor/core";
import { MessageLeft, MessageRight } from "./Message";
import "moment/locale/pt-br";
import { createEventListener } from "../../../../assets/utils/redux/actions";
import { connect, useDispatch, useStore } from "react-redux";
import { InView } from "react-intersection-observer";
import LoadingGif from "../../../../components/loadingGif/LoadingGif";
import createFilterFieldAllTypes from "../../../../assets/utils/filter/createFilterFieldAllTypes";
import FiltrosJsonProvider from "../../../../assets/utils/filter/FiltrosJsonProvider";
import SearchComponent from "../../../../components/SearchComponent";
import ShowMoreButton from "../../../../components/showMoreButton/ShowMoreButton";
import ShowDate from "./ShowDate";

const { SecureStoragePlugin } = Plugins;
const moment = require("moment");
moment.locale("pt-br");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
    },
    paper2: {
      width: "80vw",
      maxWidth: "500px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
    },
    container: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    messagesBody: {
      width: "calc( 100% - 20px )",
      margin: 10,
      overflowY: "auto",
      height: "calc(100% - 130px)",
      boxShadow: "none",
    },
  })
);

interface ChatProps {
  idPedidoExtra?: any;
  descricaoPedido?: any;
  pedidoDisabled?: boolean;
  idUsuarioPortal: any;
  propsRouter: any;
  onSetShowModalChat: any;
}

interface Registro {
  id: any;
  idPedidoExtra: any;
  idUsuario: any;
  idUsuarioPortal: any;
  msg: any;
  excluida: number;
  dataHoraEnvio: any;
}

interface RegistroPedido {
  idPedidoExtra: any;
  totalMsgNaoLidas: any;
  dataHoraEnvio: any;
}

// let msgsLeitura: any = [];
// let digitando = false;
// let digitandoTimeOut = false;
let lastSearch = "";
let msg: any = null;
let idChatUltimaLeitura: any = null;
let chatsLidas: any = [];
let scrollTimer: any = -1;
const msgLoadingDeafult = "Carregando chat...";

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const Chat: React.FC<ChatProps> = (props: any) => {
  const [idPedidoExtra, setIdPedidoExtra] = useState<number>(
    props.idPedidoExtra
  );
  const [descPedidoExtra, setDescPedidoExtra] = useState<string>(
    props.descricaoPedido
  );
  const [showAlert, setShowAlert] = useState<object>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showLoadingPedidos, setShowLoadingPedidos] = useState<boolean>(false);
  const [showLoadingMsg, setShowLoadingMsg] =
    useState<string>(msgLoadingDeafult);
  const [enviando, setEnviando] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState(false);
  const [pageIsVisible, setPageIsVisible] = useState(false);
  const [scrollBottom, setScrollBottom] = useState<any>(null);
  const [pageHasFocus, setPageHasFocus] = useState(true);
  const [usuariosDigitando, setUsuariosDigitando] = useState<[]>([]);
  const [idUsuarioPortal, setIdUsuarioPortal] = useState<number>();
  const [registros, setRegistros] = useState<Registro[]>([]);
  const [pedidos, setPedidos] = useState<RegistroPedido[]>([]);
  const [registroRemove, setRegistroRemove] = useState<any>();
  const [width, setWidth] = useState(getWidth());
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<any>();
  const classes = useStyles();
  const dispatch = useDispatch();
  const store = useStore();

  let datas: any = [];
  let dataTimes: any = [];
  let numeroNovasMensagens: number = 0;
  let buscandoDadosServer = false;

  SecureStoragePlugin.get({ key: "idUsuario" })
    .then((idUsuario: any) => {
      setIdUsuarioPortal(parseInt(idUsuario.value));
    })
    .catch((error: any) => {
      setIdUsuarioPortal(-1);
    });

  useEffect(() => {
    // msgsLeitura = [];
    buscandoDadosServer = true;
    findPedidos(page, null);
    findMensagens(false);
    onFocusPage();
  }, [idPedidoExtra]);

  // usado pelo redux
  useEffect(() => {
    if (
      buscandoDadosServer ||
      (props.registroSend?.idPedidoExtra ||
        props.registroSend?.pedidoExtraChat?.idPedidoExtra ||
        props.idPedidoExtra) != idPedidoExtra
    ) {
      findPedidos(page, filter, false);
      return;
    }

    let registroAux = { ...props.registroSend };
    if (props.tipoAcao === "saveMsg") {
      findPedidos(page, filter, false);
      setPageIsVisible(false);

      if (props.idUsuarioPortalRedux != idUsuarioPortal) {
        //se não foi o usuário que criou a msg então não rola o scroll pra baixo
        let registrosLocal = [...registros];
        deleteUltimaLeitura(
          props.tipoAcao,
          registrosLocal,
          props.registroSend,
          (regs: any) => {
            setMensagensLocal(registroAux, false);
          }
        );
      } else {
        //se usuário que criou a msg então rola o scroll pra baixo
        let registrosLocal = [...registros];
        deleteUltimaLeitura(
          props.tipoAcao,
          registrosLocal,
          props.registroSend,
          (regs: any) => {
            setMensagensLocal(registroAux, true);
          }
        );
      }
    } else if (props.tipoAcao === "saveLeitura") {
      findPedidos(page, filter, false);
      let registrosLocal = [...registros];
      deleteUltimaLeitura(
        props.tipoAcao,
        registrosLocal,
        registroAux,
        (regs: any) => {
          leituraMensagensLocal(registroAux);
        }
      );
    } else if (props.tipoAcao === "deleteMsg") {
      deleteMensagensLocal(registroAux);
    }
  }, [props.registroSend, props.tipoAcao]);

  // usado pelo redux
  useEffect(() => {
    if (props.usuarios != usuariosDigitando)
      setUsuariosDigitando(props.usuarios);
  }, [props.usuarios]);

  // usado pelo redux
  useEffect(() => {
    if (props.ultimaMsgAparecendo != null) {
      let element = document.getElementById("paper-msgs");
      if (element) {
        // se a pagina não criou scroll
        if (element.scrollHeight <= element.clientHeight) {
          beforeSaveLeitura(props.ultimaMsgAparecendo);
        }
      }
    }
  }, [props.ultimaMsgAparecendo]);

  const beforeSaveLeitura = (idChat: any) => {
    if (scrollTimer != -1) clearTimeout(scrollTimer);

    scrollTimer = window.setTimeout(() => {
      if (idChat != null) {
        saveMensagemLida(idChat, idUsuarioPortal);
      }
    }, 500);
  };

  const findPedidos = (
    nextPage: number,
    filterAux: any = [],
    showLoading: boolean = true,
    callback?: () => void
  ) => {
    ajax({
      url:
        new ConfigProvider().getConfig("baseURLG3WSCotacao") +
        "/compras/pedidos/extras/consulta/load/app/chat",
      data: {
        page: nextPage,
        start: (nextPage - 1) * 15,
        limit: 15,
        filterAva: filterAux || [],
        sort: [],
      },
      fnSetShowLoading: showLoading ? setShowLoadingPedidos : null,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        if (nextPage == 1) {
          setPedidos(response.registros);
        } else {
          setPedidos((prev) => [...prev, ...response.registros]);
        }

        if (!idPedidoExtra) {
          setIdPedidoExtra(response.registros[0]?.pedidoExtra?.id);
          setDescPedidoExtra(response.registros[0]?.pedidoExtra?.descricao);
        }

        setPage(nextPage);
        if (callback) callback(); // Finaliza a ação do scroll
      },
    });
  };

  const setMensagensLocal = (
    registro: any,
    moverScroll?: boolean,
    callback?: any
  ) => {
    if (registro == null) return;

    let registrosLocal = [...registros];
    registrosLocal.push(registro);

    getUltimaLeitura(registrosLocal);
    setRegistros(registrosLocal);

    if (callback) callback(registrosLocal);

    setFocus(moverScroll);

    setTimeout(() => {
      let textarea = document.getElementsByName("textarea");
      if (textarea && textarea[1]) textarea[1].focus();
    }, 500);
  };

  const setFocus = (moverScroll: any) => {
    if (moverScroll == true) {
      //chama isso na primeira vez que abre o chat
      moveScrollToMsg();
    } else if (scrollBottom) {
      //se o chat está aberto então rola para final quando receber novas msgs
      moveScrollToEnd();
    }
  };

  const leituraMensagensLocal = (registro: any) => {
    if (registro == null) return;
    let registrosLocal = [...registros];
    const reg: any = registrosLocal.find((object: any) => {
      return object.id == registro.idChat;
    });

    if (reg) {
      if (!reg.usuariosLeitura) reg.usuariosLeitura = [];

      reg.usuariosLeitura =
        registro.pedidoExtraChat.usuariosLeitura || reg.usuariosLeitura;
      reg.usuariosUltimaLeitura =
        registro.pedidoExtraChat.usuariosUltimaLeitura ||
        reg.usuariosUltimaLeitura;

      getUltimaLeitura(registrosLocal);
      setRegistros(registrosLocal);
    }
  };

  const deleteUltimaLeitura = (
    tipoAcao: any,
    registrosLocal: any,
    registroSend: any,
    callback?: any
  ) => {
    if (registroSend == null) return;

    const regUltimaLeitura: any = registrosLocal.find((object: any) => {
      if (!object.usuariosUltimaLeitura) return null;

      return object.usuariosUltimaLeitura.find((objectAux: any) => {
        return objectAux.idUsuarioPortal == registroSend.idUsuarioPortal;
      });
    });
    
    if (regUltimaLeitura) {
      // se a msg da confirmação de leitura for menor que a ultima confirmação gravada então não faz nada
      if (
        tipoAcao === "saveLeitura" &&
        registroSend.idChat &&
        regUltimaLeitura.id > registroSend.idChat
      ) {
        if (callback) callback(registrosLocal);
        return;
      }

      // acha e deleta essa ultima leitura
      let pos = regUltimaLeitura.usuariosUltimaLeitura.findIndex(
        (object: any) => {
          return object.idUsuarioPortal == registroSend.idUsuarioPortal;
        }
      );
      if (pos != -1) regUltimaLeitura.usuariosUltimaLeitura.splice(pos, 1);
    }

    if (callback) callback(registrosLocal);
  };

  const deleteMensagensLocal = (registro: any) => {
    let registrosLocal = [...registros];

    const pos = registrosLocal.findIndex((object: any) => {
      return object.id == registro.id;
    });
    registrosLocal[pos].msg = "Esta mensagem foi removida.";
    registrosLocal[pos].excluida = 1;

    getUltimaLeitura(registrosLocal);
    setRegistros(registrosLocal);
  };

  const findMensagens = (naoMostrarGif?: boolean) => {
    if (!idPedidoExtra) return;

    ajax({
      url:
        new ConfigProvider().getConfig("baseURLG3WSCotacao") +
        "/compras/pedidos/extras/chat/consulta/load/app",
      data: {
        page: 1,
        start: 0,
        limit: 100000,
        filterAva: [],
        filterFix: [
          {
            field: "idPedidoExtra",
            value: idPedidoExtra,
            type: "int",
            comparison: "eq",
            connector: "AND",
          },
        ],
        sort: [{ property: "dataHoraEnvio", direction: "ASC" }],
      },
      fnSetShowLoading: !naoMostrarGif ? setShowLoading : null,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        getUltimaLeitura(response.registros);
        setRegistros(response.registros);
        moveScrollToMsg();

        setTimeout(() => {
          let textarea = document.getElementsByName("textarea");
          if (textarea && textarea[1]) textarea[1].focus();
        }, 500);

        if (response.responseCallback) {
          response.responseCallback();
        }

        buscandoDadosServer = false;
      },
      beforeMsgSuccessFalse: function (response: any) {
        buscandoDadosServer = false;
      },
    });
  };

  const saveMensagem = (msgAux?: any) => {
    const ms =
      msgAux != null && msgAux.trim() !== ""
        ? msgAux
        : msg != null && msg.trim() !== ""
        ? msg
        : null;

    if (ms == null) {
      setEnviando(false);
      return;
    }

    const registroAux = {
      id: null,
      idPedidoExtra: idPedidoExtra,
      idUsuario: null,
      idUsuarioPortal: idUsuarioPortal,
      msg: ms,
      dataHoraEnvio: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    setEnviando(true);

    try {
      ajax({
        url:
          new ConfigProvider().getConfig("baseURLG3WSCotacao") +
          "/compras/pedidos/extras/chat/cadastro/save/app",
        data: {
          registro: registroAux,
        },
        headers: [
          {
            key: "idRegistro",
            value: idPedidoExtra,
          },
        ],
        fnSetShowLoading: null,
        fnSetShowAlert: setShowAlert,
        beforeMsgSuccessTrue: function (response: any) {
          msg = "";
          setEnviando(false);

          let textarea: any = document.getElementsByName("textarea");
          textarea[1].value = "";

          if (response && response.responseCallback) {
            response.responseCallback();
          }
          setShowLoadingMsg(msgLoadingDeafult);
        },
        beforeMsgSuccessFalse: function (response: any) {
          setEnviando(false);
          if (response && response.responseCallback) {
            response.responseCallback();
          }
          setShowLoadingMsg(msgLoadingDeafult);
        },
        beforeMsgSuccessFailure: function (response: any) {
          setEnviando(false);
          if (response && response.responseCallback) {
            response.responseCallback();
          }
          setShowLoadingMsg(msgLoadingDeafult);
        },
      });
    } catch (exception) {
      setEnviando(false);
      setShowLoadingMsg(msgLoadingDeafult);
    }
  };

  // const setDigitar = (valor: any) => {
  //   try {
  //     ajax({
  //       url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/chat/cadastro/digitando',
  //       data: {
  //         registro: {
  //           mensagem: valor,
  //           idPedidoExtra: idPedidoExtra
  //         }
  //       },
  //       fnSetShowLoading: () => { },
  //       fnSetShowAlert: () => { },
  //       beforeMsgSuccessTrue: function (response: any) {
  //         if (valor == null || valor === "") {
  //           digitando = true;
  //         } else {
  //           digitando = false;
  //         }
  //       }
  //     });
  //   }
  //   catch (exception) {
  //   }
  // }

  const onRemoveChat = () => {
    if (registroRemove == null) return;

    setShowLoadingMsg("Removendo mensagem...");

    ajax({
      url:
        new ConfigProvider().getConfig("baseURLG3WSCotacao") +
        "/compras/pedidos/extras/chat/cadastro/delete/app",
      data: {
        registros: [registroRemove],
      },
      headers: [
        {
          key: "idRegistro",
          value: idPedidoExtra,
        },
      ],
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
        setShowAlert({
          isOpen: true,
          duration: 4000,
          color: "success",
          header: "Sucesso",
          message: "Mensagem removida com sucesso!",
          buttons: ["OK"],
          onDidDismiss: () => {
            setShowAlert({ isOpen: false });
          },
        });

        if (response.responseCallback) {
          response.responseCallback();
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      },
      beforeMsgSuccessFailure: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      },
    });
  };

  const saveMensagemLida = (idChat: any, idUsuarioPortal: any) => {
    if (idChat == null || idChat === "") return;

    if (!chatsLidas.includes(idChat)) {
      chatsLidas.push(idChat);
    } else {
      return;
    }

    const registroAux = {
      id: null,
      idChat: idChat,
      idUsuario: null,
      idUsuarioPortal: idUsuarioPortal,
      dataHoraGravacao: moment().format("YYYY-MM-DD HH:mm:ss"),
    };

    try {
      ajax({
        url:
          new ConfigProvider().getConfig("baseURLG3WSCotacao") +
          "/compras/pedidos/extras/chat/lidas/cadastro/save/app/varias",
        data: {
          registro: registroAux,
        },
        headers: [
          {
            key: "idRegistro",
            value: idPedidoExtra,
          },
        ],
        fnSetShowLoading: null,
        fnSetShowAlert: () => {},
        beforeMsgSuccessTrue: function (response: any, request: any) {
          if (response && response.responseCallback) {
            response.responseCallback();
          }
        },
        beforeMsgSuccessFalse: function (response: any, request: any) {
          if (request.registro && request.registro.idChat)
            removeChatLidas(request.registro.idChat);
          if (response && response.responseCallback) {
            response.responseCallback();
          }
        },
        beforeMsgSuccessFailure: function (response: any, request: any) {
          if (request.registro && request.registro.idChat)
            removeChatLidas(request.registro.idChat);
          if (response && response.responseCallback) {
            response.responseCallback();
          }
        },
      });
    } catch (exception) {}
  };

  const removeChatLidas = (idChat: any) => {
    const pos = chatsLidas.findIndex((id: any) => {
      return id == idChat;
    });
    if (pos != -1) {
      chatsLidas.splice(pos, 1);
    }
  };

  const moveScrollToEnd = () => {
    setTimeout(() => {
      if (scrollBottom == null) return;

      let element = document.getElementById("paper-msgs");
      if (element) {
        if (element.scrollHeight <= element.clientHeight) return;
        element.scrollTop = element.scrollHeight;
      }
    }, 100);
  };

  const moveScrollToMsg = () => {
    SecureStoragePlugin.get({ key: "idUsuario" })
      .then((idUsuario: any) => {
        let marcadorLeitura = document.getElementById(
          "avatar-user-" + idUsuario.value
        );

        if (marcadorLeitura) {
          var rect = marcadorLeitura.getBoundingClientRect();
          let element = document.getElementById("paper-msgs");
          if (element) {
            if (element.scrollHeight <= element.clientHeight) {
              return;
            }

            element.scrollTop =
              rect.top + element.scrollTop - element.clientHeight;

            if (
              element.scrollHeight - element.scrollTop - element.clientHeight <=
              50
            ) {
              element.scrollTop = rect.top + element.scrollTop;
            }
          }
        }
      })
      .catch((error: any) => {});
  };

  const onFocusPage = () => {
    if (window.location.hash === "#chatModal") {
      const fn = (event: any) => {
        if (document.hidden) {
          setPageHasFocus(false);
        } else {
          setPageHasFocus(false);
        }
      };

      if (!store.getState().createEventListener.created) {
        dispatch(createEventListener(true));
        document.addEventListener("visibilitychange", fn, true);
      }
    }
  };

  const getUltimaLeitura = (registros: any) => {
    SecureStoragePlugin.get({ key: "idUsuario" })
      .then((idUsuario: any) => {
        const idUsuarioPortal = idUsuario.value;
        let id = null;
        if (registros) {
          let regAux = [...registros];
          for (let registro of regAux.reverse()) {
            for (let usuarioUltimaLeitura of registro.usuariosUltimaLeitura) {
              if (
                usuarioUltimaLeitura.idUsuarioPortal != null &&
                usuarioUltimaLeitura.idUsuarioPortal == idUsuarioPortal
              ) {
                idChatUltimaLeitura = registro.id;
                id = registro.id;
                break;
              }
            }
            if (id) break;
          }
        }
      })
      .catch((error: any) => {});
  };

  const existsUserDigitandoPedido = (idPedidoExtra: any) => {
    const pos = usuariosDigitando.findIndex((object: any) => {
      return object.idPedidoExtra == idPedidoExtra;
    });
    return pos != -1;
  };

  const onSearch = (value: any) => {
    if (lastSearch == value) return;

    lastSearch =
      !value || value == undefined || value.trim() == "" ? "" : value;

    let filterFields = new Array();
    if (lastSearch !== "") {
      createFilterFieldAllTypes(
        lastSearch,
        FiltrosJsonProvider.getJsonFiltrosPedidos(),
        filterFields,
        "cn"
      );
    }
    if (filterFields.length > 0) {
      setPage(1);
    }

    setFilter(filterFields);
    findPedidos(1, filterFields);
  };

  // Instância do controlador de menu
  const abrirFecharMenu = async () => {
    try {
      const isEnabled = await menuController.isEnabled();

      if (!isEnabled) {
        return;
      }

      const isOpen = await menuController.isOpen();

      if (isOpen) {
        await menuController.close();
      } else {
        await menuController.open();
      }
    } catch (error) {
      console.error("Erro ao abrir/fechar menu:", error);
    }
  };

  return (
    <>
      <IonHeader className="ionGridViewport-border-red">
        <IonGrid className="ionGridViewport">
          <IonRow>
            <IonCol className="ionColViewport" size={"4"}>
              <IonToolbar>
                <IonButtons slot="start">
                  <FilterContainer>
                    <Tooltip
                      arrow
                      title={
                        <React.Fragment>
                          <h5 style={{ margin: 2, padding: 0 }}>
                            Atualizar mensagens
                          </h5>
                        </React.Fragment>
                      }
                    >
                      <IonIcon
                        slot="start"
                        size="large"
                        icon={refreshCircleOutline}
                        className="icon-refresh"
                        onClick={() => {
                          findPedidos(page, filter);
                          findMensagens();
                        }}
                      />
                    </Tooltip>
                  </FilterContainer>
                </IonButtons>
              </IonToolbar>
            </IonCol>

            <IonCol
              className="ionColViewport"
              style={{ height: "inherit" }}
              size={"4"}
            >
              <IonToolbar></IonToolbar>
            </IonCol>

            <IonCol style={{ height: "inherit" }} className="ionColViewport">
              <IonToolbar>
                <IonButtons slot="end">
                  <FilterContainer>
                    <FilterItem
                      iconType
                      onClick={() => {
                        props.onSetShowModalChat(false);
                      }}
                      icon={
                        <>
                          <div
                            style={{ marginLeft: "8px", marginRight: "8px" }}
                          >
                            <G3FecharEfeitoIcon className="myIcon" />
                          </div>
                        </>
                      }
                      colorText="#404237"
                      padding={0}
                      tolltipTitle={"Ação: Fechar tela"}
                    />
                  </FilterContainer>
                </IonButtons>
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>

      <IonContent>
        <IonToast
          isOpen={false}
          cssClass="my-custom-class"
          mode="ios"
          {...showAlert}
        />

        <div className={classes.container}>
          <IonSplitPane contentId="main-content">
            {/* Menu Lateral */}
            <IonMenu contentId="main-content">
              <IonHeader>
                <IonToolbar className="toolbarWhite">
                  <IonTitle>Pedidos</IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent>
                {/* Campo de Pesquisa */}
                <SearchComponent onSearch={onSearch} />

                <IonList>
                  {/* GIF animado de carregamento */}
                  <LoadingGif show={showLoadingPedidos} msg={""} />

                  {pedidos && pedidos.length > 0 ? (
                    pedidos.map((row: any, idx: any) => (
                      <IonItem
                        key={idx}
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            idPedidoExtra &&
                            idPedidoExtra.toString() ==
                              row.pedidoExtra.id.toString()
                              ? "#dcdcdc"
                              : "transparent", // Marca o item com uma cor diferente
                        }}
                        onClick={() => {
                          setIdPedidoExtra(row.pedidoExtra.id);
                          setDescPedidoExtra(row.pedidoExtra.descricao);
                        }}
                      >
                        <IonLabel color="dark">
                          <ShowMoreButton
                            somenteLimitar
                            content={
                              row.pedidoExtra.id +
                              " - " +
                              row.pedidoExtra.descricao
                            }
                            limit={35}
                          />
                          <ShowDate date={row.dataHoraEnvio} />
                        </IonLabel>

                        {row.totalMsgNaoLidas > 0 && (
                          <IonBadge
                            color="success"
                            style={{ marginLeft: "10px", fontSize: "12px" }}
                          >
                            {row.totalMsgNaoLidas}
                          </IonBadge>
                        )}
                      </IonItem>
                    ))
                  ) : (
                    <IonItem
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",
                      }}
                    >
                      <IonLabel color="dark">
                        <ShowMoreButton
                          somenteLimitar
                          content={"Nenhum pedido encontrado"}
                          limit={35}
                        />
                      </IonLabel>
                    </IonItem>
                  )}
                </IonList>

                {/* Scroll Infinito */}
                <IonInfiniteScroll
                  onIonInfinite={(ev: any) => {
                    findPedidos(page + 1, filter, false, () =>
                      ev.target?.complete()
                    );
                  }}
                  threshold="100px"
                >
                  <IonInfiniteScrollContent loadingText="Carregando mais conversas..." />
                </IonInfiniteScroll>
              </IonContent>
            </IonMenu>

            {/* Conteúdo Principal */}
            <IonPage id="main-content">
              <IonHeader>
                <IonToolbar className="toolbarWhite">
                  {/* Botão para abrir o menu manualmente */}
                  <IonButtons slot="start">
                    <IonMenuButton autoHide={true} onClick={abrirFecharMenu} />
                  </IonButtons>
                  <IonTitle>
                    {idPedidoExtra
                      ? "Chat - Pedido: " +
                        idPedidoExtra +
                        " - " +
                        descPedidoExtra
                      : "Escolha um pedido para iniciar o chat"}
                  </IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent>
                <LoadingGif show={showLoading} msg={showLoadingMsg} />
                <Paper className={classes.paper}>
                  <Paper
                    id="paper-msgs"
                    className={classes.messagesBody}
                    onScroll={(e: any) => {
                      if (scrollTimer != -1) clearTimeout(scrollTimer);

                      scrollTimer = window.setTimeout(() => {
                        // parou de fazer o scroll
                        const idChat =
                          store.getState().scroll.ultimaMsgAparecendo;
                          
                        if (idChat != null) {
                          saveMensagemLida(idChat, idUsuarioPortal);
                        }
                      }, 500);

                      const positionScroll =
                        e.target.scrollHeight -
                        e.target.scrollTop -
                        e.target.clientHeight;
                      if (positionScroll > 0) {
                        setScrollBottom(false);
                      } else {
                        setScrollBottom(true);
                      }
                    }}
                  >
                    {registros.map((row: any, index: number) => {
                      const data = moment(row.dataHoraEnvio);
                      const dataMsg = data.format("DD/MM/YYYY");

                      let dataEnvio =
                        dataMsg == moment().format("DD/MM/YYYY")
                          ? moment(row.dataHoraEnvio).format("HH:mm")
                          : moment(row.dataHoraEnvio).format(
                              "DD/MM/YYYY HH:mm"
                            );
                      // const dataTimeMsg = data.format("DD/MM/YYYY HH:mm");
                      if (
                        !dataTimes.includes(
                          dataEnvio + "-" + row.idUsuarioPortal
                        )
                      ) {
                        dataTimes.push(dataEnvio + "-" + row.idUsuarioPortal);
                      } else {
                        if (registros[index - 1]) {
                          if (
                            registros[index - 1].idUsuarioPortal ==
                            row.idUsuarioPortal
                          ) {
                            dataEnvio = null;
                          }
                        } else {
                          dataEnvio = null;
                        }
                      }
                      if (
                        idChatUltimaLeitura == row.id &&
                        registros.length != index + 1
                      ) {
                        numeroNovasMensagens = registros.length - (index + 1);
                      }

                      const mostraAvisoNovaMensagem =
                        idChatUltimaLeitura == row.id &&
                        registros.length != index + 1 &&
                        row.idUsuarioPortal != idUsuarioPortal;

                      const newsMsgs =
                        pageIsVisible && mostraAvisoNovaMensagem ? (
                          <span className={"data-msgs-news"}>
                            <div className="data-margin">Novas mensagens</div>
                          </span>
                        ) : (
                          ""
                        );

                      let dataCorrida = data.format("LLLL");
                      dataCorrida = dataCorrida.substr(
                        0,
                        dataCorrida.length - 9
                      );
                      const separadorData =
                        dataMsg == moment().format("DD/MM/YYYY") ? (
                          <div className="data-margin">Hoje</div>
                        ) : (
                          <div className="data-margin">{dataCorrida}</div>
                        );

                      let incluirSeparador = false;
                      if (!datas.includes(dataMsg)) {
                        datas.push(dataMsg);
                        incluirSeparador = true;
                      }
                      const appendSeparator = incluirSeparador ? (
                        <span className="data-msgs">{separadorData}</span>
                      ) : (
                        ""
                      );

                      const displayName =
                        row.usuarioPortal != null &&
                        row.usuarioPortal.nome != null
                          ? (row.usuarioPortal.nome != null &&
                            row.usuarioPortal.nome != ""
                              ? row.usuarioPortal.nome
                              : "Sem nome") +
                            (row.usuarioPortal.nomeEmpresa != null
                              ? " - " + row.usuarioPortal.nomeEmpresa
                              : "")
                          : row.usuario != null && row.usuario.nome != null
                          ? (row.usuario.nome != null && row.usuario.nome != ""
                              ? row.usuario.nome
                              : "Sem nome") +
                            (row.usuario.nomeEmpresa != null
                              ? " - " + row.usuario.nomeEmpresa
                              : "")
                          : "Sem nome";

                      if (
                        row.idUsuario != null ||
                        (row.idUsuarioPortal != null &&
                          row.idUsuarioPortal != idUsuarioPortal)
                      ) {
                        return (
                          <div key={index}>
                            {appendSeparator}
                            <MessageLeft
                              key={index}
                              id={row.id}
                              message={row.msg}
                              excluida={row.excluida}
                              lidaPorMim={row.lidaPorMim}
                              timestamp={dataEnvio}
                              idUsuarioPortalLogado={idUsuarioPortal}
                              idUsuarioPortalMsg={row.idUsuarioPortal}
                              idChatUltimaLeitura={idChatUltimaLeitura}
                              usuariosUltimaLeitura={
                                row.usuariosUltimaLeitura || []
                              }
                              usuariosLeitura={row.usuariosLeitura || []}
                              displayName={displayName}
                              avatarDisp={true}
                              width={width}
                            />
                            {newsMsgs}

                            {mostraAvisoNovaMensagem ? (
                              <InView
                                delay={10}
                                as="div"
                                onChange={(inView, entry) => {
                                  if (
                                    entry.intersectionRatio == 1 &&
                                    !pageIsVisible &&
                                    pageHasFocus
                                  ) {
                                    setPageIsVisible(true);
                                  }
                                }}
                              ></InView>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      } else {
                        return (
                          <div key={index}>
                            {appendSeparator}
                            <MessageRight
                              key={index}
                              id={row.id}
                              message={row.msg}
                              excluida={row.excluida}
                              lidaPorMim={row.lidaPorMim}
                              timestamp={dataEnvio}
                              idUsuarioPortalLogado={idUsuarioPortal}
                              idUsuarioPortalMsg={row.idUsuarioPortal}
                              idChatUltimaLeitura={idChatUltimaLeitura}
                              usuariosUltimaLeitura={
                                row.usuariosUltimaLeitura || []
                              }
                              usuariosLeitura={row.usuariosLeitura || []}
                              avatarDisp={true}
                              width={width}
                              onRemove={(idAux: any) => {
                                setRegistroRemove({
                                  id: idAux,
                                });
                                setShowPopover(true);
                              }}
                            />
                          </div>
                        );
                      }
                    })}
                  </Paper>

                  <div style={{ textAlign: "right", height: 40, width: "95%" }}>
                    <span style={{ fontWeight: "normal", display: "flex" }}>
                      {enviando ? (
                        <>
                          {"Enviando mensagem..."}
                          <IonSpinner name="dots" className="gif-digitando" />
                        </>
                      ) : (
                        ""
                      )}
                    </span>

                    {usuariosDigitando.length > 0 ? (
                      <span style={{ fontWeight: "normal", display: "flex" }}>
                        {usuariosDigitando.map((item: any, index: any) => {
                          if (item.idPedidoExtra != idPedidoExtra) return;

                          if (index > 0 && index != usuariosDigitando.length) {
                            item.usuario += ",";
                          }
                          return item.usuario;
                        })}
                        {existsUserDigitandoPedido(idPedidoExtra)
                          ? (usuariosDigitando.length > 1
                              ? " estão"
                              : " está") + " digitando "
                          : ""}
                        {existsUserDigitandoPedido(idPedidoExtra) ? (
                          <IonSpinner name="dots" className="gif-digitando" />
                        ) : (
                          ""
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div
                    style={{
                      textAlign: "right",
                      top: "calc(100% - 120px)",
                      position: "absolute",
                      right: 25,
                    }}
                    hidden={scrollBottom == null || scrollBottom}
                  >
                    <IonButton
                      className="btn-scroll-msg"
                      color="medium"
                      onClick={() => {
                        moveScrollToMsg();
                      }}
                    >
                      <IonIcon
                        icon={chevronDownOutline}
                        size="large"
                        className="icon-down"
                        onClick={() => moveScrollToMsg()}
                      />
                    </IonButton>

                    {numeroNovasMensagens > 0 ? (
                      <IonBadge
                        onClick={() => {
                          moveScrollToMsg();
                        }}
                        className="badge-btn-msgs"
                        color="success"
                      >
                        {numeroNovasMensagens}
                      </IonBadge>
                    ) : (
                      ""
                    )}
                  </div>

                  <IonItem className="input-send">
                    <IonIcon slot="start" size="large" icon={pencil} />

                    <IonTextarea
                      id="input-send-msg"
                      name="textarea"
                      inputmode="text"
                      disabled={!idPedidoExtra}
                      autofocus={true}
                      placeholder="Digite aqui"
                      enterkeyhint={"send"}
                      maxlength={1000}
                      rows={1}
                      spellcheck={true}
                      onIonChange={(e) => {
                        msg = e.detail.value;

                        // if (!digitando) {
                        //   digitando = true;
                        //   setDigitar(e.detail.value);
                        // }

                        // if (!digitandoTimeOut) {
                        //   digitandoTimeOut = true;
                        //   setTimeout(() => {
                        //     digitando = false;
                        //     if (!digitando) setDigitar(null);
                        //     digitandoTimeOut = false;
                        //   }, 2500);
                        // }
                      }}
                      onKeyPress={(e: any) => {
                        if (e.charCode == 13 && !e.shiftKey) {
                          e.preventDefault();

                          if (!enviando) {
                            saveMensagem(e.detail.value);
                          }
                        }
                      }}
                    ></IonTextarea>

                    <IonIcon
                      slot="end"
                      size="large"
                      icon={send}
                      className="icon-send"
                      onClick={() => {
                        if (!enviando && idPedidoExtra) {
                          saveMensagem();
                        }
                      }}
                    />
                  </IonItem>
                </Paper>
              </IonContent>
            </IonPage>
          </IonSplitPane>
        </div>

        <Popover
          open={showPopover}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          // mode="ios"
          onClose={(e) => {
            setShowPopover(false);
          }}
        >
          <div className="myPopover">
            <p>Deseja realmente remover esta mensagem?</p>
            <IonButton
              color="success"
              onClick={() => {
                onRemoveChat();
                setShowPopover(false);
              }}
              size="small"
            >
              Remover
            </IonButton>
            <IonButton
              color="danger"
              onClick={() => setShowPopover(false)}
              size="small"
            >
              Cancelar
            </IonButton>
          </div>
        </Popover>
      </IonContent>
    </>
  );
};

const mapStateToProps = (store: any) => ({
  tipoAcao: store.clickState.tipoAcao,
  atualizarMsgs: store.clickState.atualizarMsgs,
  idUsuarioPortalRedux: store.clickState.idUsuarioPortalRedux,
  registroSend: store.clickState.registroSend,
  usuarios: store.usuariosDigitacao.usuarios,
  ultimaMsgAparecendo: store.scroll.ultimaMsgAparecendo,
});

export default connect(mapStateToProps)(Chat);
