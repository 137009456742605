import React from "react";
import './LoadingGif.css';
import {
  Backdrop,
  CircularProgress,
  Typography
} from '@material-ui/core';

interface params {
  show: any;
  msg: any;
}

const LoadingGif: React.FC<params> = (props: any) => {
  return (
    <Backdrop className="loading-gif" open={props.show}>
      <CircularProgress color="inherit" />
      {props.msg && (
        <Typography variant="body1">
          {props.msg}
        </Typography>
      )}
    </Backdrop>
  );
};

export default LoadingGif;