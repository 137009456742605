import React, { useState, useEffect } from "react";
import {
  IonToast, IonButton
} from '@ionic/react';
import {
  Dialog, DialogActions, DialogTitle,
  DialogContentText, DialogContent, Popover
} from '@material-ui/core';
import ConfigProvider from '../../../assets/utils/configProvider'
import ajax from '../../../assets/utils/ajax';
import MyInput from '../../../components/form/MyInput';


interface Registro {
  id: any,
  idUsuarioAprovacao: any,
  idUsuarioPortal: any,
  onCallback?: any,
  setPedidoSelecionado: any,
  showPopoverPedirAprovador: any,
  setShowPopoverPedirAprovador: any
  alteraAprovadorOnFinalizar: any;
}

const EscolherAprovador: React.FC<Registro> = (props) => {
  const [showAlert, setShowAlert] = useState<object>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showPopoverRemover, setShowPopoverRemover] = useState<boolean>(false);
  const [idUsuarioAprovacao, setIdUsuarioAprovacao] = useState<string>("");
  const [usuariosAprovadores, setUsuariosAprovadores] = useState<[object]>();


  useEffect(() => {
    setIdUsuarioAprovacao(props.idUsuarioAprovacao == undefined || props.idUsuarioAprovacao == null ? "" : props.idUsuarioAprovacao);
    if (props.showPopoverPedirAprovador) findUsuariosAprovadores();
  }, [props.showPopoverPedirAprovador])


  const saveAprovador = (value: any, callback: any) => {
    let registroAux = { ...{ id: props.id, alteraAprovadorOnFinalizar: props.alteraAprovadorOnFinalizar }, ...value };

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/cadastro/update/aprovador/app',
      data: {
        registro: registroAux
      },
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setIdUsuarioAprovacao(value.idUsuarioAprovacao);

        if (callback) callback(true);
        if (response.responseCallback) response.responseCallback()
        if (props.onCallback) props.onCallback(props.id);
        if (props.setPedidoSelecionado) props.setPedidoSelecionado(null);
      },
      beforeMsgSuccessFalse: function (response: any) {
        if (callback) callback(false);
        if (response.responseCallback) response.responseCallback();
      },
      beforeMsgSuccessFailure: function (response: any) {
        if (callback) callback(false);
        if (response.responseCallback) response.responseCallback();
      }
    });
  }

  const removeAprovador = () => {
    if (idUsuarioAprovacao == undefined || idUsuarioAprovacao == null || idUsuarioAprovacao == "") {
      return;
    }

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/cadastro/delete/aprovador/app',
      data: {
        registro: {
          id: props.id
        }
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setIdUsuarioAprovacao("");

        if (response.responseCallback) response.responseCallback()
        if (props.onCallback) props.onCallback(props.id);
      },
      beforeMsgSuccessFalse: function (response: any) {
        if (response.responseCallback) response.responseCallback();
      },
      beforeMsgSuccessFailure: function (response: any) {
        if (response.responseCallback) response.responseCallback();
      }
    });
  }

  const findUsuariosAprovadores = () => {
    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/portal/usuarios/aprovadores/consulta/load',
      data: {
        start: 0,
        page: 1,
        limit: 1000,
        filterFix: [{
          field: "idUsuario",
          value: props.idUsuarioPortal,
          type: "int",
          comparison: "eq",
          connector: "AND"
        }, {
          field: "tipoAprovacao",
          value: 1,
          type: "int",
          comparison: "eq",
          connector: "AND"
        }],
        sort: []
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        let dados = response.registros;
        setUsuariosAprovadores(dados);

        if (response.responseCallback) {
          response.responseCallback()
        }
      }
    });
  }

  return (
    <>
    <Popover
        open={showPopoverRemover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        // mode="ios"
        onClose={e => {
          setShowPopoverRemover(false)
        }}
      >
        <div className="myPopover">
          <p>Deseja realmente remover este aprovador?</p>
          <IonButton
            color="success"
            onClick={() => {
              setShowPopoverRemover(false);
              removeAprovador();
            }}
            size="small"
          >
            Sim
          </IonButton>
          <IonButton
            color="danger"
            onClick={() => {
              setShowPopoverRemover(false)
            }}
            size="small"
          >
            Não
          </IonButton>
        </div>
      </Popover>

      <Dialog
        open={props.showPopoverPedirAprovador}
        onClose={() => {
          props.setShowPopoverPedirAprovador(false)
          if (props.setPedidoSelecionado) props.setPedidoSelecionado(null);
        }}
        PaperProps={{
          component: 'form'
        }}
      >
        <DialogTitle>Escolher aprovador</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Informe o usuário que irá aprovar ou reprovar o seu pedido extra.
          </DialogContentText>

          <MyInput
            typeComponent="select"
            value={idUsuarioAprovacao == null || idUsuarioAprovacao == "" ? "" : idUsuarioAprovacao}
            onSave={saveAprovador}
            required={true}
            enableSaveNull={false}
            loadingData={showLoading}
            options={usuariosAprovadores != null ? usuariosAprovadores.map((item: any) => {
              return { value: item.idUsuarioAprovador, display: item.usuarioAprovador != null ? item.usuarioAprovador.nome : "" };
            }) : []}
            getOptionDisabled={idUsuarioAprovacao}
            width="100%"
            propsInput={{
              name: "idUsuarioAprovacao",
              label: "Usuário Aprovador",
              disabled: showLoading
            }}
          />
        </DialogContent>
        <DialogActions>
          <IonButton
            color="primary"
            disabled={idUsuarioAprovacao == null || idUsuarioAprovacao == ""}
            onClick={() => {
              setShowPopoverRemover(true);
            }}>
            Remover Aprovador
          </IonButton>

          <IonButton
            color="medium"
            onClick={() => {
              props.setShowPopoverPedirAprovador(false)
              if (props.setPedidoSelecionado) props.setPedidoSelecionado(null);
            }}>
            Sair
          </IonButton>
        </DialogActions>
      </Dialog>

      <IonToast
        isOpen={false}
        cssClass='my-custom-class'
        mode='ios'
        {...showAlert}
      />
    </>
  );
};

export default EscolherAprovador;