import { DISCONECTED_WEBSOCKET } from '../actions/actionTypes';
const initialState = {
  disconected: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DISCONECTED_WEBSOCKET:
      return {
        ...state,
        disconected: action.disconected
      };
    default:
      return state;
  }
};