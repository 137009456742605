
import { createTheme } from '@material-ui/core/styles';

const themeMaterial = createTheme({
  zIndex: {
    tooltip: 999999999999999,
    modal: 99999999
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#DD1820",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#282828",
      },
      daySelected: {
        backgroundColor: "#F5BB36",
      },
      dayDisabled: {
        color: "#858585",
      },
      current: {
        color: "#DD1820",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#DD1820",
        zIndex: 9999999999
      },
    },
  },
});

export default themeMaterial;