import { CLICK_UPDATE_VALUE } from '../actions/actionTypes';
const initialState = {
  tipoAcao: null,
  atualizarMsgs: 0,
  idUsuarioPortalRedux: null,
  nomeUsuarioDigitacao: null,
  registroSend: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLICK_UPDATE_VALUE:
      return {
        ...state,
        tipoAcao: action.tipoAcao,
        atualizarMsgs: action.atualizarMsgs,
        idUsuarioPortalRedux: action.idUsuarioPortalRedux,
        nomeUsuarioDigitacao: action.nomeUsuarioDigitacao,
        registroSend: action.registroSend
      };
    default:
      return state;
  }
};