import { Plugins } from '@capacitor/core';
import ConfigProvider from './configProvider';

const { SecureStoragePlugin } = Plugins;
var fila: any = [];
var listaGifs: any = [];
var isLoading: boolean = false;

const ajax = (props: any) => {
  const cloneProps = { ...props };

  // if (isLoading) {
  //   fila.push(cloneProps)
  // } else {
    auxAjax(cloneProps)
  // }
}

const verificaLista = (props: any) => {
  isLoading = false;
  if (fila.length > 0) {
    var auxProps = fila[0];
    fila.shift();

    const temFuncaoLoadingGif = props.fnSetShowLoading != null;
    auxAjax(auxProps, temFuncaoLoadingGif);
  }
  else {
    if (props.fnSetShowLoading) props.fnSetShowLoading(false);
    // for (const fnLoadingGif of listaGifs) {
    //   fnLoadingGif(false);
    // }
    // listaGifs = [];
  }
}

const executaFuncaoLoadingGif = (props: any, value: any) => {
  if (props.fnSetShowLoading) {
    // if (value) listaGifs.push(props.fnSetShowLoading);
    props.fnSetShowLoading(value);
  }
}

const auxAjax = (props: any, executandoLista?: any) => {
  const { url, data, noMsg } = props;

  async function request(props: any) {
    isLoading = true;

    const tok = await SecureStoragePlugin.get({ key: 'token' })
      .then((value: any) => {
        return value;
      })
      .catch((error: any) => null);

    if (!executandoLista) {
      executaFuncaoLoadingGif(props, true);
    }

    let headers = new Headers();
    if (!props.multiPartFormData) headers.append('Content-Type', 'application/json');
    // headers.append('Accept', '*/*');
    // headers.append('Accept-Encoding', 'gzip, deflate');
    // headers.append('Accept-Language', 'pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7');
    // headers.append('Connection', 'keep-alive');

    if (url.includes("public") ? false : url.indexOf("auth") === -1 || url.indexOf("authSessionExpired") !== -1) {
      headers.append('token', "" + tok.value);
      headers.append('tipoAplicacao', "" + new ConfigProvider().getConfig("tipoAplicacao"));
      // headers.append('Access-Control-Allow-Origin', '*');
    }

    var datason = JSON.stringify(data)
    var rota = url
    if (props.url) {
      if (props.url.includes("save") || props.url.includes("update")) {
        if (props.fnGetRegistro) {
          var currentRegister = props.fnGetRegistro()
          if (currentRegister.id) {
            rota = props.url.replace("save", "update")
          }
          data.registro = currentRegister
          datason = JSON.stringify(data)
        }
      }
    }

    if (props.headers) {
      for (let header of props.headers) {
        if (!headers.has(header.key)) headers.append(header.key, header.value);
      }
    }

    try {
      fetch(rota, {
        method: 'POST',
        mode: 'cors',
        body: props.multiPartFormData ? data : datason,
        headers: headers
      })
        .then(res => {
          //if (!executandoLista && props.fnSetShowLoading) {
          //props.fnSetShowLoading(false);
          //}

          //executaFuncaoLoadingGif(props, false);

          return res.json()
        })
        .then(response => {

          //if (!executandoLista && props.fnSetShowLoading) {
          //props.fnSetShowLoading(false);
          //}

          // executaFuncaoLoadingGif(props, false);

          verificaLista(props);

          if (response.success) {
            if (props.beforeMsgSuccessTrue) {
              props.beforeMsgSuccessTrue(response);
            }

            if (response.msg !== "" && response.showMsg) {
              if (props.fnSetShowAlert) {
                props.fnSetShowAlert({
                  isOpen: true,
                  duration: 3000,
                  color: 'success',
                  header: 'Sucesso',
                  message: "" + response.data.msg || response.message,
                  buttons: ['OK'],
                  onDidDismiss: () => {
                    props.fnSetShowAlert({ isOpen: false });

                    if (props.afterMsgSuccessTrue) {
                      props.afterMsgSuccessTrue(response);
                    }
                  }
                })
              }
            } else if (props.afterMsgSuccessTrue) {
              props.afterMsgSuccessTrue(response);
            }
          }
          else if (!response.success) {
            if (response.errors) {
              if (response.errors.length > 0) {
                response.errors.map((erro: any) => {
                  if (erro.id === "token" && !props.url.includes("g3auth")) {

                    setTimeout(() => {
                      async function clear() {
                        await SecureStoragePlugin.remove({ key: "idEmpresa" });
                        await SecureStoragePlugin.remove({ key: "login" });
                        await SecureStoragePlugin.remove({ key: "token" });
                        await SecureStoragePlugin.remove({ key: "nomeUsuario" });
                      }
                      clear();
                      setTimeout(() => {
                        window.location.replace(window.location.origin);
                      }, 500)
                    }, 10)
                  }
                })
              }
            }

            if (props.beforeMsgSuccessFalse) {
              props.beforeMsgSuccessFalse(response, data);
            }

            if (!noMsg) {
              if (response.errors && response.errors.length > 0) {
                let msgErro = "";
                let count = 1;
                response.errors.forEach((element: any) => {
                  if (msgErro != "") msgErro += "<br/>--------------------<br/>"
                  msgErro += element.msg;

                  if (count == response.errors.length && props.fnSetShowAlert && msgErro != "") {
                    props.fnSetShowAlert({
                      isOpen: true,
                      // duration: 4000,
                      color: 'danger',
                      header: 'Aviso',
                      message: msgErro,
                      buttons: ['OK'],
                      onDidDismiss: () => {
                        props.fnSetShowAlert({ isOpen: false });

                        if (props.afterMsgSuccessFalse) {
                          props.afterMsgSuccessFalse(response, data);
                        }
                      }
                    });
                  }
                  count++;
                });
              }
              else {
                if (props.fnSetShowAlert) {

                  let msgAux = response.msg ? response.msg : '';
                  msgAux += response.msgDetalhe ? ('<br/><br/>Detalhe: ' + response.msgDetalhe) : '';

                  props.fnSetShowAlert({
                    isOpen: true,
                    // duration: 4000,
                    color: 'danger',
                    header: 'Aviso',
                    message: msgAux != '' ? msgAux : 'Ocorreu algum erro neste procedimento!',
                    buttons: ['OK'],
                    onDidDismiss: () => {
                      props.fnSetShowAlert({ isOpen: false });

                      if (props.afterMsgSuccessFalse) {
                        props.afterMsgSuccessFalse(response, data);
                      }
                    }
                  });
                }
              }
            }
          }
        })
        .catch((e) => {
          console.log(e);

          //if (!executandoLista && props.fnSetShowLoading) {
          //props.fnSetShowLoading(false);
          //}

          // executaFuncaoLoadingGif(props, false);

          verificaLista(props);

          if (props.beforeMsgSuccessFailure) {
            props.beforeMsgSuccessFailure(data);
          }

          if (props.fnSetShowAlert && !noMsg) {
            props.fnSetShowAlert({
              isOpen: true,
              // duration: 4000,
              color: 'danger',
              header: 'Falha de Rede',
              message: "A aplicação não obteve retorno do servidor. Erro" + e,
              buttons: ['OK'],
              onDidDismiss: () => {
                props.fnSetShowAlert({ isOpen: false });

                if (props.afterMsgSuccessFailure) {
                  props.afterMsgSuccessFailure(data);
                }
              }
            })
          }
        })
    }
    catch (excpetion) {

      //if (!executandoLista && props.fnSetShowLoading) {
      //props.fnSetShowLoading(false);
      //}

      // executaFuncaoLoadingGif(props, false);

      if (props.fnSetShowAlert && !noMsg) {
        props.fnSetShowAlert({
          isOpen: true,
          // duration: 4000,
          color: 'danger',
          header: 'Falha de requisição',
          message: "Falha ao buscar informações. Erro: " + excpetion,
          buttons: ['OK'],
          onDidDismiss: () => {
            props.fnSetShowAlert({ isOpen: false });

            if (props.afterMsgSuccessFailure) {
              props.afterMsgSuccessFailure(data);
            }
          }
        })
      }

      verificaLista(props);
    }
  }

  request(props);
}

export default ajax;