import React, { useState, useEffect, useRef } from "react";
import { IonSearchbar } from "@ionic/react";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

interface SearchComponentProps {
  onSearch: (value: string) => void;
}

const SearchComponent: React.FC<SearchComponentProps> = ({ onSearch }) => {
  const [valueSearch, setValueSearch] = useState<string>("");
  const [searchOn, setSearchOn] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    onSearch(valueSearch.trim() || "");
  }, [onSearch, valueSearch]);

  return (
    <>
      {!searchOn ? (
        <>
          <IonSearchbar
            debounce={750}
            value={valueSearch}
            onIonChange={(e) => setValueSearch(e.detail.value || "")}
            onKeyDown={(e) => e.key === "Enter" && onSearch(valueSearch)}
            placeholder="Pesquisar (Texto ou Data)"
            animated
          />
        </>
      ) : (
        <InputBase
          inputRef={inputRef}
          startAdornment={
            <IconButton onClick={() => setSearchOn(false)}>
              <ArrowBackIcon />
            </IconButton>
          }
          endAdornment={
            <IconButton onClick={() => { setValueSearch(''); onSearch(''); }}>
              <HighlightOffIcon />
            </IconButton>
          }
          autoFocus
          fullWidth
          value={valueSearch}
          placeholder="Pesquisar (Texto ou Data)"
          onChange={(e) => setValueSearch(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && onSearch(valueSearch)}
        />
      )}
    </>
  );
};

export default SearchComponent;
