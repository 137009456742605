import { SCROLL } from '../actions/actionTypes';
const initialState = {
  ultimaMsgAparecendo: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SCROLL:
      return {
        ...state,
        ultimaMsgAparecendo: action.ultimaMsgAparecendo
      };
    default:
      return state;
  }
};