import React, { useEffect, useState } from "react";
import { Fab } from "@material-ui/core";
import Chat from "../../pages/viewport/pedido/chat/Chat";
import ajax from "../../assets/utils/ajax";
import ConfigProvider from "../../assets/utils/configProvider";
import { IonBadge, IonIcon, IonModal } from "@ionic/react";
import { chatboxEllipses } from "ionicons/icons";
import { connect } from "react-redux";

interface params {
  idUsuarioPortal: any;
  propsRouter: any;
}

const ButtonChat: React.FC<params> = (props: any) => {
  const [showModalChat, setShowModalChat] = useState(false);
  const [totalMsgsNaoLidas, setTotalMsgsNaoLidas] = useState(0);

  useEffect(() => {
    findTotalMsgsNaoLidas();
  }, []);

  // usado pelo redux
  useEffect(() => {
    findTotalMsgsNaoLidas();
  }, [props.atualizarMsgs]);

  const findTotalMsgsNaoLidas = () => {
    ajax({
      url:
        new ConfigProvider().getConfig("baseURLG3WSCotacao") +
        "/compras/pedidos/extras/chat/consulta/load/app/naoLidas",
      data: {
        page: 1,
        start: 0,
        limit: 15,
        filterAva: [],
        sort: [],
      },
      fnSetShowLoading: null,
      fnSetShowAlert: null,
      beforeMsgSuccessTrue: function (response: any) {
        setTotalMsgsNaoLidas(response?.total || 0);
      },
    });
  };

  return (
    <>
      <Fab
        style={{
          position: "absolute",
          right: "10px",
          bottom: "140px",
          zIndex: 90000,
          backgroundColor: "grey",
          color: "#FFFFFF",
        }}
        aria-label="edit"
        onClick={() => {
          window.location.hash = "chatModal";
          setShowModalChat(true);
        }}
      >
        <IonBadge
          className="badge-btn-notification"
          color="success"
          hidden={totalMsgsNaoLidas === 0}
        >
          {totalMsgsNaoLidas}
        </IonBadge>
        <IonIcon
          className="icon-btn-toolbar-bottom"
          slot="start"
          icon={chatboxEllipses}
        />
      </Fab>

      <IonModal
        cssClass="modalProCad"
        backdropDismiss={false}
        swipeToClose={false}
        isOpen={showModalChat}
        onDidDismiss={() => {
          setShowModalChat(false);
        }}
      >
        {showModalChat ? (
          <Chat
            idUsuarioPortal={props.idUsuarioPortal}
            propsRouter={props.propsRouter}
            onSetShowModalChat={(cond: boolean, refresh: boolean) => {
              setShowModalChat(cond);
            }}
          />
        ) : (
          ""
        )}
      </IonModal>
    </>
  );
};

const mapStateToProps = (store: any) => ({
  atualizarMsgs: store.clickState.atualizarMsgs,
});

export default connect(mapStateToProps)(ButtonChat);
