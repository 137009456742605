import React, { useEffect, useState } from 'react';
import {
  IonHeader, IonContent, IonToolbar, IonButtons, IonToast, IonButton,
  IonGrid, IonRow, IonCol
} from '@ionic/react';
import { FormControl, Popover } from '@material-ui/core';
import './UsuarioCad.css';
import FilterContainer from '../../components/filterContainer/filterContainer';
import FilterItem from '../../components/filterContainer/filterItem';
import ajax from '../../assets/utils/ajax';
import ConfigProvider from '../../assets/utils/configProvider';
import MyInput from '../../components/form/MyInput';
import MailIcon from '@material-ui/icons/Mail';
import CallIcon from '@material-ui/icons/Call';
import Grid from '@material-ui/core/Grid';
import { Plugins } from '@capacitor/core';
import LockIcon from '@material-ui/icons/Lock';
import { G3FecharEfeitoIcon, G3ExcluirIcon, G3AddTelefoneIcon, G3AddEmailIcon, G3AlteraSenhaIcon } from '../../assets/utils/g3icons';
import LoadingGif from '../../components/loadingGif/LoadingGif';

const { SecureStoragePlugin } = Plugins;



interface RegistroUsuarioPortal {
  id: any;
  idEmpresa: any;
  empresa: any;
  idPessoa: any;
  pessoa: any;
  nome: any;
  tipoContribuinte: any;
  cpfCnpj: any;
  observacao: any;
}

const novoRegistroProdutoCotacao = {
  id: null,
  idEmpresa: null,
  empresa: null,
  idPessoa: null,
  pessoa: null,
  nome: null,
  tipoContribuinte: null,
  cpfCnpj: null,
  observacao: null
};

interface Registro {
  id: null,
  idEmpresa: null,
  empresa: null,
  idPessoa: null,
  pessoa: null,
  nome: null,
  tipoContribuinte: null,
  cpfCnpj: null,
  observacao: null
}

const novoRegistro = {
  id: null,
  idEmpresa: null,
  empresa: null,
  idPessoa: null,
  pessoa: null,
  nome: null,
  tipoContribuinte: null,
  cpfCnpj: null,
  observacao: null
};

let salvou = false;

interface UsuarioCadProps {
  closeModal: any;
}

const UsuarioCad: React.FC<UsuarioCadProps> = (props) => {
  const [showAlert, setShowAlert] = useState<object>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showModalEntregas, setShowModalEntregas] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [showEmailPopover, setShowEmailPopover] = useState<any>({ show: false, email: "", id: null });
  const [showTelefonePopover, setShowTelefonePopover] = useState<any>({ show: false, email: "", id: null });
  const [registroUsuarioPortal, setRegistroUsuarioPortal] = useState<any>(novoRegistroProdutoCotacao);
  const [registroEmailsUsuarioPortal, setRegistroEmailsUsuarioPortal] = useState<Array<any>>([]);
  const [registroTelefonesUsuarioPortal, setRegistroTelefonesUsuarioPortal] = useState<Array<any>>([]);
  const [registroEmails, setRegistroEmails] = useState<Array<any>>([]);
  const [registroTelefones, setRegistroTelefones] = useState<Array<any>>([]);
  const [registro, setRegistro] = useState<Registro>(novoRegistro);
  const [focusOnRequestEnd, setFocusOnRequestEnd] = useState<boolean>(false);
  const [tab, setTab] = useState<Number>(1);
  const [emailModal, setEmailModal] = useState<boolean>(false);
  const [telefoneModal, setTelefoneModal] = useState<boolean>(false);
  const [emailVal, setEmailVal] = useState<any>("");
  const [telefoneVal, setTelefoneVal] = useState<any>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [telefoneError, setTelefoneError] = useState<boolean>(false);
  const [editarNome, setEditarNome] = useState<boolean>(false);
  const [focoNome, setFocoNome] = useState<boolean>(false);
  const [senha, setSenha] = useState<any>("");
  const [confirmaSenha, setConfirmaSenha] = useState<any>("");

  const carregaInfo = async () => {
    await SecureStoragePlugin.get({ key: 'idUsuario' })
      .then((value: any) => {
        findUserInfo(value.value)
      })
  }

  useEffect(() => {
    carregaInfo()
  }, [])

  const showAlertTimout = (json: any, timout: any) => {
    setShowAlert(json);
    setTimeout(() => {
      setShowAlert({
        isOpen: false,
      });
    }, timout)
  }

  const formatFone = (value: any) => {
    if (value) {
      var endValue = value.toString().slice(value.toString().length - 4, value.toString().length);
      var startValue = value.toString().slice(0, value.toString().length - (endValue.toString().length));
      var valAux = startValue + "-" + endValue;
      return valAux
    } else {
      return ""
    }
  }

  const findEmailInfo = (userid: any) => {
    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/portal/usuarios/emails/consulta/load',
      data: {
        filterFix: [
          {
            value: userid,
            type: "numeric",
            comparison: "in",
            connector: "AND",
            field: "idUsuario"
          }
        ],
        page: 1,
        start: 0,
        limit: "1000",
        sort: [
          {
            property: "principal",
            direction: "ASC"
          }
        ]
      },
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setRegistroEmailsUsuarioPortal(response.registros);
        setRegistroEmails(response.registros);
        findTelefoneInfo(userid)
        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        setFocusOnRequestEnd(true);
        setShowLoading(false);
      },
      beforeMsgSuccessFailure: function (response: any) {
        setFocusOnRequestEnd(true);
        setShowLoading(false);
      }
    });
  }

  const findTelefoneInfo = (userid: any) => {
    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/portal/usuarios/telefones/consulta/load',
      data: {
        filterRow: [
          {
            value: userid,
            type: "numeric",
            comparison: "in",
            connector: "AND",
            field: "idUsuario"
          }
        ],
        page: 1,
        start: 0,
        limit: "1000",
        sort: [
          {
            property: "id",
            direction: "ASC"
          }
        ]
      },
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setRegistroTelefonesUsuarioPortal(response.registros);
        setRegistroTelefones(response.registros);
        setShowLoading(false);
        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        setFocusOnRequestEnd(true);
        setShowLoading(false);
      },
      beforeMsgSuccessFailure: function (response: any) {
        setFocusOnRequestEnd(true);
        setShowLoading(false);
      }
    });
  }

  const findUserInfo = (userID: any) => {
    setShowLoading(true);

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/portal/usuarios/cadastro/exists/basic/field/nome',
      data: {
        registro: {
          id: userID
        }
      },
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setRegistroUsuarioPortal(response.registro);
        setRegistro(response.registro);
        findEmailInfo(userID)
        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        setFocusOnRequestEnd(true);
        setShowLoading(false);
      },
      beforeMsgSuccessFailure: function (response: any) {
        setFocusOnRequestEnd(true);
        setShowLoading(false);
      }
    });
  }

  const saveUsuario = (value: any, callback: any) => {
    let registroAux = { ...registro, ...value };
    registroAux.empresa = null
    registroAux.pessoa = null
    delete registroAux.portalUsuario
    delete registroAux.existFornecedores
    delete registroAux.empresa
    delete registroAux.pessoa

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/portal/usuarios/cadastro/update/nome',
      data: {
        registro: registroAux
      },
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setRegistro(response.registro);
        setRegistroUsuarioPortal(response.registro)
        salvou = true;
        if (callback) callback(true);
        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        if (callback) callback(false);
      },
      beforeMsgSuccessFailure: function (response: any) {
        if (callback) callback(false);
      }
    });
  }

  const saveEmail = (value: any, callback: any, index: any) => {
    let registroAux = registroEmails
    let registro = { ...registroAux[index], ...value }
    registro.portalUsuario = null

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/portal/usuarios/emails/cadastro/' + (registro.id ? "update" : "save"),
      data: {
        registro: registro
      },
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        salvou = true;
        if (callback) callback(true);
        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        if (callback) callback(false);
      },
      beforeMsgSuccessFailure: function (response: any) {
        if (callback) callback(false);
      }
    });
  }

  const saveTelefone = (value: any, callback: any, index: any) => {

    let registroAux = registroTelefones
    if (value.telefone.toString().match(/\d/g) != null) {
      var valor = value.telefone.toString().match(/\d/g).join("")
      var ddd = valor.slice(0, 2)
      var telefone = valor.slice(2, valor.length)
    } else {
      var ddd: any = ""
      var telefone: any = ""
    }

    let registroTelAux = {
      ddd,
      telefone
    }
    let registro = { ...registroAux[index], ...registroTelAux }
    registro.portalUsuario = null

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/portal/usuarios/telefones/cadastro/' + (registro.id ? "update" : "save"),
      data: {
        registro: registro
      },
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        salvou = true;
        if (callback) callback(true);
        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        if (callback) callback(false);
      },
      beforeMsgSuccessFailure: function (response: any) {
        if (callback) callback(false);
      }
    });
  }

  const newEmail = async (value: any) => {
    await SecureStoragePlugin.get({ key: 'idUsuario' })
      .then((idUser: any) => {
        let registroAux = {
          id: "",
          idUsuario: idUser.value,
          email: value,
          principal: 2
        }

        ajax({
          url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/portal/usuarios/emails/cadastro/save',
          data: {
            registro: registroAux
          },
          fnSetShowAlert: setShowAlert,
          beforeMsgSuccessTrue: function (response: any) {
            setEmailVal("")
            setRegistro(response.registro);
            SecureStoragePlugin.get({ key: 'idUsuario' })
              .then((value: any) => {
                findUserInfo(value.value)
              })
            setEmailModal(false);
            salvou = true;
            showAlertTimout({
              isOpen: true,
              color: "success",
              message: "E-mail adicionado com sucesso!"
            }, 5000)
            if (response.responseCallback) {
              response.responseCallback()
            }
          },
          beforeMsgSuccessFalse: function (response: any) {

          },
          beforeMsgSuccessFailure: function (response: any) {

          }
        });
      })
  }

  const newTelefone = (value: any) => {
    SecureStoragePlugin.get({ key: 'idUsuario' })
      .then((userId: any) => {
        var valor = value.toString().match(/\d/g).join("")
        var ddd = valor.slice(0, 2)
        var telefone = valor.slice(2, valor.length)
        let registroAux = {
          id: "",
          idUsuario: userId.value,
          ddd,
          telefone
        }

        ajax({
          url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/portal/usuarios/telefones/cadastro/save',
          data: {
            registro: registroAux
          },
          fnSetShowAlert: setShowAlert,
          beforeMsgSuccessTrue: function (response: any) {
            setTelefoneVal("")
            SecureStoragePlugin.get({ key: 'idUsuario' })
              .then((value: any) => {
                findUserInfo(value.value)
              })
            setTelefoneModal(false);
            salvou = true;
            showAlertTimout({
              isOpen: true,
              color: "success",
              message: "Telefone adicionado com sucesso!"
            }, 5000)
            if (response.responseCallback) {
              response.responseCallback()
            }
          },
          beforeMsgSuccessFalse: function (response: any) {
            if (response.errors) response.errors.map((erro: any) => {
              showAlertTimout({
                isOpen: true,
                color: "danger",
                message: erro.msg
              }, 5000)
            })
          },
          beforeMsgSuccessFailure: function (response: any) {
            if (response.errors) response.errors.map((erro: any) => {
              showAlertTimout({
                isOpen: true,
                color: "danger",
                message: erro.msg
              }, 5000)
            })
          }
        });
      })
  }

  const removeEmail = (id: any, callback: any) => {
    SecureStoragePlugin.get({ key: 'idUsuario' })
      .then((idUser: any) => {
        ajax({
          url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/portal/usuarios/emails/cadastro/delete',
          data: {
            registros: [
              {
                id,
                idUsuario: idUser.value
              }
            ]
          },
          fnSetShowAlert: setShowAlert,
          beforeMsgSuccessTrue: function (response: any) {
            SecureStoragePlugin.get({ key: 'idUsuario' })
              .then((value: any) => {
                findUserInfo(value.value)
              })
            salvou = true;
            if (callback) callback(true);
            if (response.responseCallback) {
              response.responseCallback()
            }
          },
          beforeMsgSuccessFalse: function (response: any) {
            if (callback) callback(false);
          },
          beforeMsgSuccessFailure: function (response: any) {
            if (callback) callback(false);
          }
        });
      })
  }

  const removeTelefone = (id: any, callback: any) => {
    SecureStoragePlugin.get({ key: 'idUsuario' })
      .then((idUser: any) => {
        ajax({
          url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/portal/usuarios/telefones/cadastro/delete',
          data: {
            registros: [
              {
                id,
                idUsuario: idUser.value
              }
            ]
          },
          fnSetShowAlert: setShowAlert,
          beforeMsgSuccessTrue: function (response: any) {
            SecureStoragePlugin.get({ key: 'idUsuario' })
              .then((value: any) => {
                findUserInfo(value.value)
              })
            salvou = true;
            if (callback) callback(true);
            if (response.responseCallback) {
              response.responseCallback()
            }
          },
          beforeMsgSuccessFalse: function (response: any) {
            if (callback) callback(false);
          },
          beforeMsgSuccessFailure: function (response: any) {
            if (callback) callback(false);
          }
        });
      })
  }

  const atualizaSenha = (callback: any) => {
    if (senha != "" || confirmaSenha != "") {
      SecureStoragePlugin.get({ key: 'idUsuario' })
        .then((idUser: any) => {
          ajax({
            url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/portal/usuarios/cadastro/update/senha/app',
            data: {
              idUsuario: idUser.value,
              senha,
              confirmaSenha
            },
            fnSetShowAlert: setShowAlert,
            beforeMsgSuccessTrue: function (response: any) {
              SecureStoragePlugin.get({ key: 'idUsuario' })
                .then((value: any) => {
                  findUserInfo(value.value)
                })
              salvou = true;
              showAlertTimout({
                isOpen: true,
                color: "success",
                message: "Senha atualizada com sucesso!"
              }, 5000)
              setTab(1)
              if (callback) callback(true);
              if (response.responseCallback) {
                response.responseCallback()
              }
            },
            beforeMsgSuccessFalse: function (response: any) {
              if (callback) callback(false);
            },
            beforeMsgSuccessFailure: function (response: any) {
              if (callback) callback(false);
            }
          });
        })
    }

  }

  return (
    <>
      <IonHeader>
        <IonGrid>
          <IonRow>
            <form onClick={() => {
              if (!editarNome) {
                setEditarNome(false)
                setFocoNome(true)
                setTimeout(() => {
                  setFocoNome(false)
                }, 200)
              }
            }} style={{ width: "90%" }}>
              <>
                <p
                  hidden={editarNome}
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '15px',
                    paddingLeft: '20px'
                  }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      {registroUsuarioPortal.nome}
                    </Grid>
                    {/* <Grid item>
                      <EditIcon style={{ color: "#949494" }} />
                    </Grid> */}
                  </Grid>
                </p>
                <MyInput
                  hidden={!editarNome}
                  typeComponent="text"
                  value={registroUsuarioPortal.nome}
                  type="string"
                  width="100%"
                  onSave={saveUsuario}
                  focusOnRequestEnd={focusOnRequestEnd}
                  propsStyle={{
                    textAlign: 'center',
                    fontWeight: 'bold'
                  }}
                  propsInput={{
                    name: "nome",
                    placeholder: "Nome",
                    autoFocus: true,
                    required: true,
                  }}
                  doFocus={focoNome}
                  onBlur={() => {
                    setEditarNome(false)
                  }}
                />
              </>
            </form>

            <IonCol style={{ height: "inherit" }} className="ionColViewport">
              <IonToolbar className="toolbarWhite">
                <IonButtons slot="end">
                  <FilterContainer backgroundColor="white">
                    <FilterItem
                      iconType
                      onClick={() => {
                        props.closeModal()
                      }}
                      icon={<><G3FecharEfeitoIcon className="myIcon" /></>}
                      colorText="#404237"
                      padding={0}
                      tolltipTitle={'Fechar: Fechar tela'}
                    />
                  </FilterContainer>
                </IonButtons>
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>
      <FilterContainer backgroundColor="#404237">
        <FilterItem
          iconType
          borderJustWhenSelected
          onClick={() => {
            if (!emailModal && !telefoneModal) {
              setTab(0)
            }
          }}
          icon={<><LockIcon /></>}
          colorText={tab == 0 ? "#F5BB36" : "#FFFFFF"}
          description={"Alterar Senha"}
          tolltipTitle={'Alterar Senha'}
          tooltip={'Senha do Usuário'}
          colorBorderBottom="#F5BB36"
          selected={tab == 0}
          spaceBtween={10}
        />

        <FilterItem
          iconType
          borderJustWhenSelected
          onClick={() => {
            if (!emailModal && !telefoneModal) {
              setTab(1)
            }
          }}
          icon={<><MailIcon /></>}
          colorText={tab == 1 ? "#F5BB36" : "#FFFFFF"}
          description={"E-mails"}
          tolltipTitle={'E-mails'}
          tooltip={'E-mails do usuário'}
          colorBorderBottom="#F5BB36"
          selected={tab == 1}
          spaceBtween={10}
        />

        <FilterItem
          iconType
          borderJustWhenSelected
          onClick={() => {
            if (!emailModal && !telefoneModal) {
              setTab(2)
            }
          }}
          icon={<><CallIcon /></>}
          colorText={tab == 2 ? "#F5BB36" : "#FFFFFF"}
          description={"Telefones"}
          tolltipTitle={'Telefones'}
          tooltip={'Telefones do usuário'}
          colorBorderBottom="#F5BB36"
          selected={tab == 2}
          spaceBtween={10}
        />
      </FilterContainer>
      <IonContent>

        <LoadingGif show={showLoading} msg={'Carregando informações...'} />

        {!showLoading ? <FormControl
          fullWidth
          variant="outlined"
          required
        >
          <div hidden={emailModal || telefoneModal} >
            <div hidden={tab != 0}>
              <form style={{ margin: 15 }}>
                <>
                  <MyInput
                    typeComponent="text"
                    type="password"
                    width="100%"
                    value={senha}
                    onChange={(val: any) => {
                      setSenha(val)
                    }}
                    propsInput={{
                      name: "senha",
                      placeholder: "Senha",
                      required: true
                    }}
                  />

                  <MyInput
                    typeComponent="text"
                    type="password"
                    width="100%"
                    value={confirmaSenha}
                    onChange={(val: any) => {
                      setConfirmaSenha(val)
                    }}
                    onEnter={() => {
                      if (!emailModal && !telefoneModal) {
                        atualizaSenha((success: any) => {
                          if (success) {
                            setSenha("")
                            setConfirmaSenha("")
                          }
                        })
                      }
                    }}
                    propsInput={{
                      name: "confirmaSenha",
                      placeholder: "Confirmar Senha",
                      required: true
                    }}
                  />

                </>
              </form>
            </div>
            <div style={{ padding: 10 }} hidden={tab != 1}>
              {registroEmailsUsuarioPortal.map((email, index) => {
                var principal = email.principal == 1 ? "(principal)" : "alternativo " + index + " "
                return (
                  <>
                    <div style={{ display: "flex", flexDirection: "row", padding: 5 }}>
                      <form style={{ flex: "auto", marginRight: 10 }}>
                        <MyInput
                          email
                          typeComponent="text"
                          value={email.email}
                          type="string"
                          width="auto"
                          onSave={(value: any, callback: any) => {
                            saveEmail(value, callback, index)
                          }}
                          propsInput={{
                            name: "email",
                            placeholder: "E-mail " + principal + " " + ":",
                            required: true
                          }}
                        />
                      </form>
                      <IonButtons hidden={email.principal == 1}>
                        <FilterItem
                          iconType
                          onClick={() => {
                            setShowEmailPopover({ ...showEmailPopover, ...{ show: true, email: email.email, id: email.id } });
                          }}
                          description={"Remover e-mail"}
                          icon={<div><G3ExcluirIcon className="myIcon" /></div>}
                          colorText="#404237"
                          padding={10}
                          tooltip={'Clique para remover o seu e-mail ' + email.email + ' do seu perfil.'}
                          tolltipTitle={'Remover e-mail'}
                        />
                      </IonButtons>
                    </div>
                  </>
                )
              })}
            </div>
            <div style={{ padding: 10 }} hidden={tab != 2}>
              {registroTelefonesUsuarioPortal.map((telefone, index) => {
                return (
                  <>
                    <div style={{ display: "flex", flexDirection: "row", padding: 5 }}>
                      <form style={{ flex: "auto", marginRight: 10 }}>
                        <MyInput
                          cellphone
                          typeComponent="text"
                          value={"(" + telefone.ddd + ") " + telefone.telefone.toString().slice(0, telefone.telefone.toString().length - 4) + "-" + telefone.telefone.toString().slice(telefone.telefone.toString().length - 4, telefone.telefone.toString().length)}
                          type="string"
                          width="auto"
                          onSave={(value: any, callback: any) => {
                            saveTelefone(value, callback, index)
                          }}
                          propsInput={{
                            name: "telefone",
                            placeholder: "Telefone " + index + " " + ":",
                            required: true
                          }}
                        />
                      </form>
                      <IonButtons>
                        <FilterItem
                          iconType
                          onClick={() => {
                            setShowTelefonePopover({ ...showTelefonePopover, ...{ show: true, ddd: telefone.ddd, telefone: telefone.telefone, id: telefone.id } });
                          }}
                          description={"Remover Tel."}
                          icon={<div><G3ExcluirIcon className="myIcon" /></div>}
                          colorText="#404237"
                          padding={10}
                          tooltip={'Clique para remover o seu telefone ' + telefone.telefone + ' do seu perfil.'}
                          tolltipTitle={'Remover Telefone'}
                        />
                      </IonButtons>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
          <div hidden={!emailModal}>
            <form style={{ flex: "auto", margin: 15 }}>
              <MyInput
                email
                typeComponent="text"
                value={emailVal}
                type="string"
                width="auto"
                onChange={(val: any) => {
                  setEmailVal(val)
                }}
                onEnter={() => {
                  if (!emailError) {
                    newEmail(emailVal)
                  }
                }}
                setValidatedInput={(valid: any) => {
                  setEmailError(valid)
                }}
                propsInput={{
                  name: "email",
                  placeholder: "Novo E-mail:",
                  required: true,
                }}
              />
            </form>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <IonButton
                  color="success"
                  onClick={() => {
                    if (!emailError) {
                      newEmail(emailVal)
                    }
                  }}
                  size="small"
                >
                  Adicionar
                </IonButton>
                <IonButton
                  color="danger"
                  onClick={() => {
                    setEmailModal(!emailModal);
                    setEmailVal("")
                  }}
                  size="small"
                >
                  Cancelar
                </IonButton>
              </Grid>
            </Grid>
          </div>
          <div hidden={!telefoneModal}>
            <form style={{ flex: "auto", margin: 15 }}>
              <MyInput
                cellphone
                typeComponent="text"
                value={telefoneVal}
                type="string"
                width="auto"
                onChange={(val: any) => {
                  setTelefoneVal(val)
                }}
                onEnter={() => {
                  if (!telefoneError) {
                    newTelefone(telefoneVal)
                  }
                }}
                setValidatedInput={(valid: any) => {
                  setTelefoneError(valid)
                }}
                propsInput={{
                  name: "telefone",
                  placeholder: "Novo telefone:",
                  required: true
                }}
              />
            </form>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <IonButton
                  color="success"
                  onClick={() => {
                    if (!telefoneError) {
                      newTelefone(telefoneVal)
                    }
                  }}
                  size="small"
                >
                  Adicionar
                </IonButton>
                <IonButton
                  color="danger"
                  onClick={() => {
                    setTelefoneModal(!telefoneModal);
                    setTelefoneVal("")
                  }}
                  size="small"
                >
                  Cancelar
                </IonButton>
              </Grid>
            </Grid>
          </div>
        </FormControl> : ''}

        <IonToast
          isOpen={false}
          cssClass='my-custom-class'
          mode='ios'
          {...showAlert}
        />

        <div className="container">
          <Popover
            open={showPopover}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            onClose={e => {
              setShowPopover(false)
            }}
          >
            <div className="myPopover">
              <p>Deseja realmente desfazer as informações do produto cotado?</p>
              <IonButton
                color="success"
                onClick={() => {
                  setShowPopover(false);
                }}
                size="small"
              >
                Remover
              </IonButton>
              <IonButton
                color="danger"
                onClick={() => setShowPopover(false)}
                size="small"
              >
                Cancelar
              </IonButton>
            </div>
          </Popover>

          <Popover
            open={showEmailPopover.show}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            onClose={e => {
              setShowEmailPopover({ ...showEmailPopover, ...{ show: false } })
            }}
          >
            <div className="myPopover">
              <p>Deseja remover o e-mail {showEmailPopover.email} ?</p>
              <IonButton
                color="success"
                onClick={() => {
                  removeEmail(showEmailPopover.id, () => {
                    setShowEmailPopover({ ...showEmailPopover, ...{ show: false } })
                  })
                }}
                size="small"
              >
                Remover
              </IonButton>
              <IonButton
                color="danger"
                onClick={() => setShowEmailPopover({ ...showEmailPopover, ...{ show: false } })}
                size="small"
              >
                Cancelar
              </IonButton>
            </div>
          </Popover>

          <Popover
            open={showTelefonePopover.show}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            onClose={e => {
              setShowTelefonePopover({ ...showTelefonePopover, ...{ show: false } })
            }}
          >
            <div className="myPopover">
              <p>Deseja remover o telefone: <br /> ({showTelefonePopover.ddd}) {formatFone(showTelefonePopover.telefone)} ?</p>
              <IonButton
                color="success"
                onClick={() => {
                  removeTelefone(showTelefonePopover.id, () => {
                    setShowTelefonePopover({ ...showTelefonePopover, ...{ show: false } })
                  })
                }}
                size="small"
              >
                Remover
              </IonButton>
              <IonButton
                color="danger"
                onClick={() => setShowTelefonePopover({ ...showTelefonePopover, ...{ show: false } })}
                size="small"
              >
                Cancelar
              </IonButton>
            </div>
          </Popover>
        </div>
      </IonContent>
      <FilterContainer backgroundColor="#404237">
        <FilterItem
          iconType
          borderJustWhenSelected
          hidden={tab != 0 || emailModal || telefoneModal}
          onClick={() => {
            if (!emailModal && !telefoneModal) {
              atualizaSenha((success: any) => {
                if (success) {
                  setSenha("")
                  setConfirmaSenha("")
                }
              })
            }
          }}
          icon={<><div><G3AlteraSenhaIcon className="myIcon" /></div></>}
          colorText={"#FFFFFF"}
          description={"Alterar Senha"}
          tolltipTitle={'Alterar Senha'}
          tooltip={'Alterar a senha do seu usuário!'}
          colorBorderBottom="#F5BB36"
          selected={false}
          spaceBtween={10}
        />
        <FilterItem
          iconType
          borderJustWhenSelected
          hidden={tab != 1 || emailModal || telefoneModal}
          onClick={() => {
            setEmailModal(!emailModal);
          }}
          icon={<><div><G3AddEmailIcon className="myIcon" /></div></>}
          colorText={"#FFFFFF"}
          description={"Novo E-mail"}
          tolltipTitle={'Novo E-mail'}
          tooltip={"Clique para vincular um e-mail em sua conta."}
          colorBorderBottom="#F5BB36"
          selected={false}
          spaceBtween={10}
        />
        <FilterItem
          iconType
          borderJustWhenSelected
          hidden={tab != 2 || emailModal || telefoneModal}
          onClick={() => {
            setTelefoneModal(!telefoneModal);
          }}
          icon={<><div><G3AddTelefoneIcon className="myIcon" /></div></>}
          colorText={"#FFFFFF"}
          description={"Novo Telefone"}
          tolltipTitle={'Novo Telefone'}
          tooltip={'Adiciooner telefone para seu usuário!'}
          colorBorderBottom="#F5BB36"
          selected={false}
          spaceBtween={10}
        />
      </FilterContainer>
    </>
  );
};

export default UsuarioCad;
