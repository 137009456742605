import { PowerOffSharp } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';

interface IconProps {
    width?: any;
    height?: any;
    className?: any;
}

export const G3AddIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M4.5 9H0V40.5C0 42.975 2.025 45 4.5 45H36V40.5H4.5V9ZM40.5 0H13.5C11.025 0 9 2.025 9 4.5V31.5C9 33.975 11.025 36 13.5 36H40.5C42.975 36 45 33.975 45 31.5V4.5C45 2.025 42.975 0 40.5 0ZM38.25 20.25H29.25V29.25H24.75V20.25H15.75V15.75H24.75V6.75H29.25V15.75H38.25V20.25Z" fill="#F5BB36" />
        </svg>
    )
}

export const G3AddIconDisabled: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M4.5 9H0V40.5C0 42.975 2.025 45 4.5 45H36V40.5H4.5V9ZM40.5 0H13.5C11.025 0 9 2.025 9 4.5V31.5C9 33.975 11.025 36 13.5 36H40.5C42.975 36 45 33.975 45 31.5V4.5C45 2.025 42.975 0 40.5 0ZM38.25 20.25H29.25V29.25H24.75V20.25H15.75V15.75H24.75V6.75H29.25V15.75H38.25V20.25Z" fill="#F5BB36" fillOpacity="0.4" />
        </svg>
    )
}

export const G3AddEmailIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 52 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M41.2714 0H4.58571C2.06357 0 0.0229285 2.07321 0.0229285 4.60714L0 32.25C0 34.7839 2.06357 36.8571 4.58571 36.8571H41.2714C43.7936 36.8571 45.8571 34.7839 45.8571 32.25V4.60714C45.8571 2.07321 43.7936 0 41.2714 0ZM41.2714 9.21429L22.9286 20.7321L4.58571 9.21429V4.60714L22.9286 16.125L41.2714 4.60714V9.21429Z" fill="white" />
            <path d="M51.4286 32.8162H42.2449V41.9999H39.1837V32.8162H30V29.755H39.1837V20.5713H42.2449V29.755H51.4286V32.8162Z" fill="#F5BB36" />
        </svg>
    )
}

export const G3AddPretoIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 53" {...svgProps}><defs /><g filter="url(#filter0_d)"><path d="M8.5 9H4v31.5C4 42.98 6.03 45 8.5 45H40v-4.5H8.5V9zm36-9h-27A4.51 4.51 0 0013 4.5v27c0 2.48 2.03 4.5 4.5 4.5h27c2.48 0 4.5-2.02 4.5-4.5v-27C49 2.02 46.98 0 44.5 0zm-2.25 20.25h-9v9h-4.5v-9h-9v-4.5h9v-9h4.5v9h9v4.5z" fill="#333" /></g><defs><filter id="filter0_d" x="0" y="0" width="53" height="53" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"><feFlood floodOpacity="0" result="BackgroundImageFix" /><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" /><feOffset dy="4" /><feGaussianBlur stdDeviation="2" /><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" /><feBlend in2="BackgroundImageFix" result="effect1_dropShadow" /><feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" /></filter></defs></svg>
    )
}

export const G3AddTelefoneIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M10.86 23.37C15.18 31.86 22.14 38.79 30.63 43.14L37.23 36.54C38.04 35.73 39.24 35.46 40.29 35.82C43.65 36.93 47.28 37.53 51 37.53C52.65 37.53 54 38.88 54 40.53V51C54 52.65 52.65 54 51 54C22.83 54 0 31.17 0 3C0 1.35 1.35 0 3 0H13.5C15.15 0 16.5 1.35 16.5 3C16.5 6.75 17.1 10.35 18.21 13.71C18.54 14.76 18.3 15.93 17.46 16.77L10.86 23.37Z" fill="white" />
            <path d="M47.5435 19.9564H38.7391V28.7608H35.8043V19.9564H27V17.0216H35.8043V8.21729H38.7391V17.0216H47.5435V19.9564Z" fill="#F5BB36" />
        </svg>
    )
}

export const G3AlteraSenhaIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 39" {...svgProps}><defs /><path d="M26.44 13.2H24.6V9.54a9.17 9.17 0 00-18.33 0v3.66H4.44a3.68 3.68 0 00-3.66 3.67V35.2a3.68 3.68 0 003.66 3.66h22a3.68 3.68 0 003.66-3.66V16.87a3.68 3.68 0 00-3.66-3.67zm-13.72 7.44c-2.02 0-.94 7.41-.94 5.4 0-2.02 8.4 8.43 4.22-3.04-1 2-6.24 1.02-6.24 3.03 0 2.02 4.97-5.39 2.96-5.39zm8.4-7.44H9.76V9.54a5.69 5.69 0 0111.36 0v3.66z" fill="#fff" /><path d="M24.09 34.92l-5.7-5.9-1.9 1.97 7.6 7.87 16.28-16.88-1.9-1.97-14.38 14.91z" fill="#F5BB36" /></svg>
    )
}

export const G3ArrowBackIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 31 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M31 39.7125L11.8394 22.5L31 5.2875L25.1012 0L0 22.5L25.1012 45L31 39.7125Z" fill="#F5BB36" />
        </svg>
    )
}

export const G3ArrowForwardIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 32 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M0.00045747 5.29482L19.5029 22.5248L0.00245007 39.757L6.00647 45.0498L31.5529 22.5241L6.00387 0.00126736L0.00045747 5.29482Z" fill="#F5BB36" />
        </svg>
    )
}

export const G3ArrowBackEfeitoIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 31 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M31 39.7125L11.8394 22.5L31 5.2875L25.1012 0L0 22.5L25.1012 45L31 39.7125Z" fill="#F5BB36" />
        </svg>
    )
}

export const G3ArrowBackEfeitoDisableIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 31 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M31 39.7125L11.8394 22.5L31 5.2875L25.1012 0L0 22.5L25.1012 45L31 39.7125Z" fill="#F5BB36" fillOpacity="0.4" />
        </svg>

    )
}


export const G3ArrowForwardEfeitoIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 32 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M0.00045747 5.29482L19.5029 22.5248L0.00245007 39.757L6.00647 45.0498L31.5529 22.5241L6.00387 0.00126736L0.00045747 5.29482Z" fill="#F5BB36" />
        </svg>
    )
}

export const G3ArrowForwardEfeitoDisableIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 32 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M0.00045747 5.29482L19.5029 22.5248L0.00245007 39.757L6.00647 45.0498L31.5529 22.5241L6.00387 0.00126736L0.00045747 5.29482Z" fill="#F5BB36" fillOpacity="0.4" />
        </svg>
    )
}

export const G3CaminhaoIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 70 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M63.8813 9.8718H57.7625V0H29.2077C26.9641 0 25.1284 2.22115 25.1284 4.9359V32.0833H29.2077C29.2077 36.1801 31.9408 39.4872 35.3266 39.4872C38.7123 39.4872 41.4454 36.1801 41.4454 32.0833H53.6832C53.6832 36.1801 56.4163 39.4872 59.8021 39.4872C63.1879 39.4872 65.921 36.1801 65.921 32.0833H70.0002V19.7436L63.8813 9.8718ZM35.3266 35.7853C33.6337 35.7853 32.2671 34.1317 32.2671 32.0833C32.2671 30.0349 33.6337 28.3814 35.3266 28.3814C37.0194 28.3814 38.386 30.0349 38.386 32.0833C38.386 34.1317 37.0194 35.7853 35.3266 35.7853ZM62.8615 13.5737L66.8592 19.7436H57.7625V13.5737H62.8615ZM59.8021 35.7853C58.1092 35.7853 56.7426 34.1317 56.7426 32.0833C56.7426 30.0349 58.1092 28.3814 59.8021 28.3814C61.495 28.3814 62.8615 30.0349 62.8615 32.0833C62.8615 34.1317 61.495 35.7853 59.8021 35.7853Z" fill="#404237" />
            <path d="M14.8077 4.4873C6.62115 4.4873 0 10.667 0 18.3078C0 28.6732 14.8077 43.9745 14.8077 43.9745C14.8077 43.9745 29.6154 28.6732 29.6154 18.3078C29.6154 10.667 22.9942 4.4873 14.8077 4.4873ZM14.8077 23.2437C11.8885 23.2437 9.51923 21.0324 9.51923 18.3078C9.51923 15.5832 11.8885 13.3719 14.8077 13.3719C17.7269 13.3719 20.0962 15.5832 20.0962 18.3078C20.0962 21.0324 17.7269 23.2437 14.8077 23.2437Z" fill="#F5BB36" />
        </svg>
    )
}

export const G3CaminhaoBrancoIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 73 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M66.3361 10.102H59.9821V0H30.3302C28.0004 0 26.0942 2.27296 26.0942 5.05102V32.8316H30.3302C30.3302 37.024 33.1683 40.4082 36.6842 40.4082C40.2001 40.4082 43.0382 37.024 43.0382 32.8316H55.7461C55.7461 37.024 58.5843 40.4082 62.1001 40.4082C65.616 40.4082 68.4541 37.024 68.4541 32.8316H72.6901V20.2041L66.3361 10.102ZM36.6842 36.6199C34.9263 36.6199 33.5072 34.9278 33.5072 32.8316C33.5072 30.7355 34.9263 29.0434 36.6842 29.0434C38.4421 29.0434 39.8612 30.7355 39.8612 32.8316C39.8612 34.9278 38.4421 36.6199 36.6842 36.6199ZM65.2771 13.8903L69.4284 20.2041H59.9821V13.8903H65.2771ZM62.1001 36.6199C60.3422 36.6199 58.9231 34.9278 58.9231 32.8316C58.9231 30.7355 60.3422 29.0434 62.1001 29.0434C63.8581 29.0434 65.2771 30.7355 65.2771 32.8316C65.2771 34.9278 63.8581 36.6199 62.1001 36.6199Z" fill="white" />
            <path d="M15.3766 4.5918C6.87555 4.5918 0 10.9157 0 18.7347C0 29.3418 15.3766 45 15.3766 45C15.3766 45 30.7533 29.3418 30.7533 18.7347C30.7533 10.9157 23.8777 4.5918 15.3766 4.5918ZM15.3766 23.7857C12.3452 23.7857 9.88498 21.5228 9.88498 18.7347C9.88498 15.9465 12.3452 13.6836 15.3766 13.6836C18.408 13.6836 20.8683 15.9465 20.8683 18.7347C20.8683 21.5228 18.408 23.7857 15.3766 23.7857Z" fill="#F5BB36" />
        </svg>
    )
}

export const G3CaminhaoBrancoDisableIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 73 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M66.3361 10.102H59.9821V0H30.3302C28.0004 0 26.0942 2.27296 26.0942 5.05102V32.8316H30.3302C30.3302 37.024 33.1683 40.4082 36.6842 40.4082C40.2001 40.4082 43.0382 37.024 43.0382 32.8316H55.7461C55.7461 37.024 58.5843 40.4082 62.1001 40.4082C65.616 40.4082 68.4541 37.024 68.4541 32.8316H72.6901V20.2041L66.3361 10.102ZM36.6842 36.6199C34.9263 36.6199 33.5072 34.9278 33.5072 32.8316C33.5072 30.7355 34.9263 29.0434 36.6842 29.0434C38.4421 29.0434 39.8612 30.7355 39.8612 32.8316C39.8612 34.9278 38.4421 36.6199 36.6842 36.6199ZM65.2771 13.8903L69.4284 20.2041H59.9821V13.8903H65.2771ZM62.1001 36.6199C60.3422 36.6199 58.9231 34.9278 58.9231 32.8316C58.9231 30.7355 60.3422 29.0434 62.1001 29.0434C63.8581 29.0434 65.2771 30.7355 65.2771 32.8316C65.2771 34.9278 63.8581 36.6199 62.1001 36.6199Z" fill="white" fillOpacity="0.4" />
            <path d="M15.3766 4.5918C6.87555 4.5918 0 10.9157 0 18.7347C0 29.3418 15.3766 45 15.3766 45C15.3766 45 30.7533 29.3418 30.7533 18.7347C30.7533 10.9157 23.8777 4.5918 15.3766 4.5918ZM15.3766 23.7857C12.3452 23.7857 9.88498 21.5228 9.88498 18.7347C9.88498 15.9465 12.3452 13.6836 15.3766 13.6836C18.408 13.6836 20.8683 15.9465 20.8683 18.7347C20.8683 21.5228 18.408 23.7857 15.3766 23.7857Z" fill="#F5BB36" fillOpacity="0.4" />
        </svg>
    )
}

export const G3CaminhaoDisableIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 70 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M63.8813 9.8718H57.7625V0H29.2077C26.9641 0 25.1284 2.22115 25.1284 4.9359V32.0833H29.2077C29.2077 36.1801 31.9408 39.4872 35.3266 39.4872C38.7123 39.4872 41.4454 36.1801 41.4454 32.0833H53.6832C53.6832 36.1801 56.4163 39.4872 59.8021 39.4872C63.1879 39.4872 65.921 36.1801 65.921 32.0833H70.0002V19.7436L63.8813 9.8718ZM35.3266 35.7853C33.6337 35.7853 32.2671 34.1317 32.2671 32.0833C32.2671 30.0349 33.6337 28.3814 35.3266 28.3814C37.0194 28.3814 38.386 30.0349 38.386 32.0833C38.386 34.1317 37.0194 35.7853 35.3266 35.7853ZM62.8615 13.5737L66.8592 19.7436H57.7625V13.5737H62.8615ZM59.8021 35.7853C58.1092 35.7853 56.7426 34.1317 56.7426 32.0833C56.7426 30.0349 58.1092 28.3814 59.8021 28.3814C61.495 28.3814 62.8615 30.0349 62.8615 32.0833C62.8615 34.1317 61.495 35.7853 59.8021 35.7853Z" fill="#404237" fillOpacity="0.72" />
            <path d="M14.8077 4.4873C6.62115 4.4873 0 10.667 0 18.3078C0 28.6732 14.8077 43.9745 14.8077 43.9745C14.8077 43.9745 29.6154 28.6732 29.6154 18.3078C29.6154 10.667 22.9942 4.4873 14.8077 4.4873ZM14.8077 23.2437C11.8885 23.2437 9.51923 21.0324 9.51923 18.3078C9.51923 15.5832 11.8885 13.3719 14.8077 13.3719C17.7269 13.3719 20.0962 15.5832 20.0962 18.3078C20.0962 21.0324 17.7269 23.2437 14.8077 23.2437Z" fill="#F5BB36" fillOpacity="0.72" />
        </svg>
    )
}

export const G3ExcluirIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 34 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M33.9576 22.7121L29.9726 24.2724L21.4938 1.51703L25.4788 0L33.9576 22.7121ZM31.3716 14.6068L28.8279 7.71517L30.2269 7.19505L32.813 14.0433L31.3716 14.6068ZM3.30673 15.6471C3.30673 14.9536 3.90025 14.3901 4.62095 14.3901C5.29925 14.3901 5.89277 14.9536 5.89277 15.6471V34.7183C5.89277 35.4551 5.29925 36.0186 4.62095 36.0186C3.90025 36.0186 3.30673 35.4551 3.30673 34.7183V15.6471ZM8.18205 15.6471C8.18205 14.9536 8.77556 14.3901 9.45387 14.3901C10.1746 14.3901 10.7681 14.9536 10.7681 15.6471V34.7183C10.7681 35.4551 10.1746 36.0186 9.45387 36.0186C8.77556 36.0186 8.18205 35.4551 8.18205 34.7183V15.6471ZM12.7606 15.6471C12.7606 14.9536 13.3117 14.3901 14.0748 14.3901C14.7531 14.3901 15.3042 14.9536 15.3042 15.6471V34.7183C15.3042 35.4551 14.7531 36.0186 14.0748 36.0186C13.3117 36.0186 12.7606 35.4551 12.7606 34.7183V15.6471ZM17.6783 15.6471C17.6783 14.9536 18.2294 14.3901 18.9501 14.3901C19.6708 14.3901 20.2643 14.9536 20.2643 15.6471V34.7183C20.2643 35.4551 19.6708 36.0186 18.9501 36.0186C18.2294 36.0186 17.6783 35.4551 17.6783 34.7183V15.6471ZM0 41.9567H23.783V9.01548H0V41.9567Z" fill="#B60D10" />
        </svg>
    )
}

export const G3FecharEfeitoIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M39.8889 3.92882L35.8715 0L19.9445 15.5759L4.01738 0L0 3.92882L15.9271 19.5048L0 35.0807L4.01738 39.0095L19.9445 23.4336L35.8715 39.0095L39.8889 35.0807L23.9618 19.5048L39.8889 3.92882Z" fill="#F5BB36" />
        </svg>
    )
}

export const G3HomeBrancoIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 53"  {...svgProps}><defs /><g filter="url(#filter0_d)"><path d="M22.75 37.5V26.25h7.5V37.5h9.38v-15h5.62L26.5 5.62 7.75 22.5h5.63v15h9.37z" fill="#fff" /></g><defs><filter id="filter0_d" x="0" y="0" width="53" height="53" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"><feFlood floodOpacity="0" result="BackgroundImageFix" /><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" /><feOffset dy="4" /><feGaussianBlur stdDeviation="2" /><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" /><feBlend in2="BackgroundImageFix" result="effect1_dropShadow" /><feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" /></filter></defs></svg>
    )
}

export const G3LimparIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 47 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M30.55 33H39.95V38.5H30.55V33ZM30.55 11H47V16.5H30.55V11ZM30.55 22H44.65V27.5H30.55V22ZM2.35 38.5C2.35 41.525 4.465 44 7.05 44H21.15C23.735 44 25.85 41.525 25.85 38.5V11H2.35V38.5ZM28.2 2.75H21.15L18.8 0H9.4L7.05 2.75H0V8.25H28.2V2.75Z" fill="#404237" />
        </svg>
    )
}

export const G3LimparAmareloIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M29.6855 31.5H38.8195V36.75H29.6855V31.5ZM29.6855 10.5H45.67V15.75H29.6855V10.5ZM29.6855 21H43.3865V26.25H29.6855V21ZM2.2835 36.75C2.2835 39.6375 4.33865 42 6.8505 42H20.5515C23.0633 42 25.1185 39.6375 25.1185 36.75V10.5H2.2835V36.75ZM27.402 2.625H20.5515L18.268 0H9.134L6.8505 2.625H0V7.875H27.402V2.625Z" fill="#F5BB36" />
        </svg>
    )
}

export const G3LimparDisableIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 47 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M30.55 33H39.95V38.5H30.55V33ZM30.55 11H47V16.5H30.55V11ZM30.55 22H44.65V27.5H30.55V22ZM2.35 38.5C2.35 41.525 4.465 44 7.05 44H21.15C23.735 44 25.85 41.525 25.85 38.5V11H2.35V38.5ZM28.2 2.75H21.15L18.8 0H9.4L7.05 2.75H0V8.25H28.2V2.75Z" fill="#404237" fillOpacity="0.4" />
        </svg>
    )
}

export const G3LimparAmareloDisableIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M29.6855 31.5H38.8195V36.75H29.6855V31.5ZM29.6855 10.5H45.67V15.75H29.6855V10.5ZM29.6855 21H43.3865V26.25H29.6855V21ZM2.2835 36.75C2.2835 39.6375 4.33865 42 6.8505 42H20.5515C23.0633 42 25.1185 39.6375 25.1185 36.75V10.5H2.2835V36.75ZM27.402 2.625H20.5515L18.268 0H9.134L6.8505 2.625H0V7.875H27.402V2.625Z" fill="#F5BB36" fillOpacity="0.4" />
        </svg>
    )
}

export const G3LimparEfeitoIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 47 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M30.55 33H39.95V38.5H30.55V33ZM30.55 11H47V16.5H30.55V11ZM30.55 22H44.65V27.5H30.55V22ZM2.35 38.5C2.35 41.525 4.465 44 7.05 44H21.15C23.735 44 25.85 41.525 25.85 38.5V11H2.35V38.5ZM28.2 2.75H21.15L18.8 0H9.4L7.05 2.75H0V8.25H28.2V2.75Z" fill="#404237" />
        </svg>
    )
}


export const G3LimparYellowEfeitoDisabledIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 47 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M30.55 33H39.95V38.5H30.55V33ZM30.55 11H47V16.5H30.55V11ZM30.55 22H44.65V27.5H30.55V22ZM2.35 38.5C2.35 41.525 4.465 44 7.05 44H21.15C23.735 44 25.85 41.525 25.85 38.5V11H2.35V38.5ZM28.2 2.75H21.15L18.8 0H9.4L7.05 2.75H0V8.25H28.2V2.75Z" fill="#404237" fillOpacity="0.4" />
        </svg>
    )
}

export const G3LimparYellowEfeitoIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 47 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M30.55 33H39.95V38.5H30.55V33ZM30.55 11H47V16.5H30.55V11ZM30.55 22H44.65V27.5H30.55V22ZM2.35 38.5C2.35 41.525 4.465 44 7.05 44H21.15C23.735 44 25.85 41.525 25.85 38.5V11H2.35V38.5ZM28.2 2.75H21.15L18.8 0H9.4L7.05 2.75H0V8.25H28.2V2.75Z" fill="#F5BB36" />
        </svg>
    )
}


export const G3LimparEfeitoDisabledIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 47 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M30.55 33H39.95V38.5H30.55V33ZM30.55 11H47V16.5H30.55V11ZM30.55 22H44.65V27.5H30.55V22ZM2.35 38.5C2.35 41.525 4.465 44 7.05 44H21.15C23.735 44 25.85 41.525 25.85 38.5V11H2.35V38.5ZM28.2 2.75H21.15L18.8 0H9.4L7.05 2.75H0V8.25H28.2V2.75Z" fill="#404237" fillOpacity="0.4" />
        </svg>
    )
}

export const G3SairBrancoIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <g clipPath="url(#clip0)">
                <path d="M13.1604 10.7275L13.1305 7.24173C13.0975 3.39756 16.198 0.243457 20.0422 0.21048L37.5579 0.0602238C41.4021 0.0272471 44.5562 3.1277 44.5892 6.97187L44.8523 37.6462C44.8853 41.4904 41.7848 44.6445 37.9407 44.6775L20.4249 44.8277C16.5807 44.8607 13.4266 41.7603 13.3937 37.9161L13.3638 34.4304C13.3555 33.4677 14.129 32.6808 15.0917 32.6726C16.0543 32.6643 16.8412 33.4378 16.8495 34.4005L16.8794 37.8862C16.8959 39.8081 18.4731 41.3585 20.395 41.342L37.9108 41.1918C39.8327 41.1753 41.3831 39.598 41.3666 37.6761L41.1034 7.00177C41.087 5.07985 39.5097 3.52946 37.5878 3.54595L20.0721 3.6962C18.1502 3.71269 16.5998 5.28991 16.6162 7.21183L16.6461 10.6976C16.6544 11.6602 15.8809 12.4471 14.9182 12.4554C13.9556 12.4636 13.1687 11.6901 13.1604 10.7275ZM1.40534 25.6747L5.34153 29.5439C6.02818 30.2189 7.13176 30.2094 7.80639 29.5228C8.48136 28.8365 8.47189 27.7329 7.78524 27.0583L4.98545 24.3058L25.912 24.1262C26.8747 24.118 27.6482 23.3311 27.6399 22.3684C27.6317 21.4058 26.8448 20.6323 25.8821 20.6405L4.95554 20.82L7.70771 18.0199C8.38268 17.3336 8.37321 16.23 7.68657 15.5554C7.34325 15.2179 6.89586 15.0515 6.44993 15.0553C6.00367 15.0592 5.5592 15.2332 5.22172 15.5765L1.35248 19.5127C-0.331896 21.2262 -0.308185 23.9903 1.40534 25.6747Z" fill="#FEFEFE" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="44.6189" height="44.6189" fill="white" transform="translate(45 44.6172) rotate(179.509)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const G3SearchIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 43 43" {...svgProps}>
            {/* <defs />
            <g filter="url(#filter0_d)"> */}
                <path fill="#fff" d="M16.99 25.01v-1.58l.54-.56a13 13 0 10-1.4-1.4l-.56.54h-1.58L4 32.01 6.98 35l10-9.99zm0-12a9 9 0 1118 0 9 9 0 11-18 0z" />
            {/* </g>
            <defs>
                <filter id="filter0_d" width="43" height="43" x="0" y="0" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
            </defs> */}
        </svg>
    )
}

export const G3UserBrancoIcon: React.FC<IconProps> = (props) => {
    var svgProps: any = {}
    if (props.width) svgProps.width = props.width;
    if (props.height) svgProps.height = props.height;
    if (props.className) svgProps.className = props.className;
    return (
        <svg viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M22.5 0C10.08 0 0 10.08 0 22.5C0 34.92 10.08 45 22.5 45C34.92 45 45 34.92 45 22.5C45 10.08 34.92 0 22.5 0ZM22.5 6.75C26.235 6.75 29.25 9.765 29.25 13.5C29.25 17.235 26.235 20.25 22.5 20.25C18.765 20.25 15.75 17.235 15.75 13.5C15.75 9.765 18.765 6.75 22.5 6.75ZM22.5 38.7C16.875 38.7 11.9025 35.82 9 31.455C9.0675 26.9775 18 24.525 22.5 24.525C26.9775 24.525 35.9325 26.9775 36 31.455C33.0975 35.82 28.125 38.7 22.5 38.7Z" fill="white" />
        </svg>
    )
}