import React, { useEffect, useState } from 'react';
import './AprovacaoOrdemCompra.css';
import { IonPage, IonContent, IonAlert, AlertButton } from '@ionic/react';
import ajax from '../../assets/utils/ajax';
import ConfigProvider from '../../assets/utils/configProvider';
import LoadingGif from '../../components/loadingGif/LoadingGif';

interface Props {
	aprovar: Boolean,
	idOrdemCompra: String,
	idToken: String
}

const AprovacaoOrdemCompra: React.FC<Props> = (props) => {
	const [showAlert, setShowAlert] = useState<object>();
	const [buttonDesfazer, setButtonDesfazer] = useState<AlertButton[]>();
	const [showLoading, setShowLoading] = useState<boolean>(false);
	const [showLoadingDesfazer, setShowLoadingDesfazer] = useState<boolean>(false);
	const acao = props.aprovar ? "Aprovação" : "reprovação";

	useEffect(() => {
		if (props.aprovar) {
			aprovar(props);
		} else {
			reprovar(props);
		}
	}, [])

	const aprovar = (props: Props) => {
		ajax({
			url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/public/compras/ordens/cadastro/aprovar/solicitacao',
			data: {
				registros: [{
					idOrdem: props.idOrdemCompra
				}]
			},
			headers: [{
				key: 'token',
				value: props.idToken
			}],
			fnSetShowLoading: setShowLoading,
			beforeMsgSuccessTrue: function (response: any) {
				enableButtonDesfazer(response);

				setShowAlert({
					isOpen: true,
					cssClass: 'my-custom-class-alert-true',
					header: "Sucesso",
					subHeader: "Ordem de compra aprovada",
					message: ("<b>Sucesso ao aprovar a ordem de compra " + props.idOrdemCompra + "</b>")
				})
			},
			beforeMsgSuccessFalse: function (response: any) {
				enableButtonDesfazer(response);

				let msg = response.msg ? response.msg : "";
				if (response.errors) {
					for (const erro of response.errors) {
						msg += erro.msg.indexOf("Unauthorized") !== -1 ? "Usuário não autorizado" : erro.msg;
					}
				}

				setShowAlert({
					isOpen: true,
					cssClass: 'my-custom-class-alert-false',
					header: "Aviso",
					subHeader: "Ordem de compra não foi aprovada",
					message: msg
				})
			},
			beforeMsgSuccessFailure: function (response: any) {
				let msg = response.msg ? response.msg : "Falha ao aprovar a ordem de compra";
				if (response.errors) {
					for (const erro of response.errors) {
						msg += erro.msg.indexOf("Unauthorized") !== -1 ? "Usuário não autorizado" : erro.msg;
					}
				}

				setShowAlert({
					isOpen: true,
					cssClass: 'my-custom-class-alert-false',
					header: "Aviso",
					subHeader: "Ordem de compra não foi aprovada",
					message: msg
				})
			}
		});
	}

	const reprovar = (props: Props) => {
		ajax({
			url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/public/compras/ordens/cadastro/rejeitar/solicitacao',
			data: {
				registros: [{
					idOrdem: props.idOrdemCompra
				}]
			},
			headers: [{
				key: 'token',
				value: props.idToken
			}],
			fnSetShowLoading: setShowLoading,
			beforeMsgSuccessTrue: function (response: any) {
				enableButtonDesfazer(response);

				setShowAlert({
					isOpen: true,
					cssClass: 'my-custom-class-alert-true',
					header: "Sucesso",
					subHeader: "Ordem de compra reprovada",
					message: ("<b>Sucesso ao reprovar a ordem de compra " + props.idOrdemCompra + "</b>")
				})
			},
			beforeMsgSuccessFalse: function (response: any) {
				enableButtonDesfazer(response);

				let msg = response.msg ? response.msg : "";
				if (response.errors) {
					for (const erro of response.errors) {
						msg += erro.msg.indexOf("Unauthorized") !== -1 ? "Usuário não autorizado" : erro.msg;
					}
				}

				setShowAlert({
					isOpen: true,
					cssClass: 'my-custom-class-alert-false',
					header: "Aviso",
					subHeader: "Ordem de compra não foi reprovada",
					message: msg
				})
			},
			beforeMsgSuccessFailure: function (response: any) {
				let msg = response.msg ? response.msg : "Falha ao reprovar a ordem de compra";
				if (response.errors) {
					for (const erro of response.errors) {
						msg += erro.msg.indexOf("Unauthorized") !== -1 ? "Usuário não autorizado" : erro.msg;
					}
				}

				setShowAlert({
					isOpen: true,
					cssClass: 'my-custom-class-alert-false',
					header: "Aviso",
					subHeader: "Ordem de compra não foi reprovada",
					message: msg
				})
			}
		});
	}

	const desfazer = (props: Props) => {
		setShowAlert({ isOpen: false });

		ajax({
			url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/public/compras/ordens/cadastro/desfazer/acao',
			data: {
				registro: {
					idOrdem: props.idOrdemCompra
				}
			},
			headers: [{
				key: 'token',
				value: props.idToken
			}],
			fnSetShowLoading: setShowLoadingDesfazer,
			beforeMsgSuccessTrue: function (response: any) {
				setButtonDesfazer([]);

				setShowAlert({
					isOpen: true,
					cssClass: 'my-custom-class-alert-true',
					header: "Sucesso",
					subHeader: "Ordem de compra desfeita",
					message: ("<b>Sucesso ao desfazer " + acao + " da ordem de compra " + props.idOrdemCompra + "</b>")
				})
			},
			beforeMsgSuccessFalse: function (response: any) {
				let msg = response.msg ? response.msg : "";
				if (response.errors) {
					for (const erro of response.errors) {
						msg += erro.msg.indexOf("Unauthorized") !== -1 ? "Usuário não autorizado" : erro.msg;
					}
				}

				setShowAlert({
					isOpen: true,
					cssClass: 'my-custom-class-alert-false',
					header: "Aviso",
					subHeader: "Ordem de compra não foi desfeita",
					message: msg
				})
			},
			beforeMsgSuccessFailure: function (response: any) {
				let msg = response.msg ? response.msg : ("Falha ao desfazer " + acao + " da ordem de compra");
				if (response.errors) {
					for (const erro of response.errors) {
						msg += erro.msg.indexOf("Unauthorized") !== -1 ? "Usuário não autorizado" : erro.msg;
					}
				}

				setShowAlert({
					isOpen: true,
					cssClass: 'my-custom-class-alert-false',
					header: "Aviso",
					subHeader: "Ordem de compra não foi desfeita",
					message: msg
				})
			}
		});
	}

	const enableButtonDesfazer = (response: any) => {
		if (response.registro) {
			if (!response.registro.situacaoAprovacao || response.registro.situacaoAprovacao === 1) {
				// pendente
			}
			else if (response.registro.situacaoAprovacao === 2) {
				setButtonDesfazer(
					[{
						text: ('Desfazer Aprovação'),
						role: ('Desfazer Aprovação'),
						handler: () => desfazer(props)
					}]
				);
			}
			else if (response.registro.situacaoAprovacao === 9) {
				setButtonDesfazer(
					[{
						text: ('Desfazer Reprovação'),
						role: ('Desfazer Reprovação'),
						handler: () => desfazer(props)
					}]
				);
			}
		}
	}

	return (
		<IonPage>

			<IonContent>
				<LoadingGif show={showLoading} msg={props.aprovar ? 'Aprovando ordem de compra...' : 'Reprovando ordem de compra...'} />
				<LoadingGif show={showLoadingDesfazer} msg={props.aprovar ? 'Desfazendo aprovação da ordem de compra...' : 'Desfazendo reprovação da ordem de compra...'} />

				<IonAlert
					isOpen={false}
					backdropDismiss={false}
					mode='ios'
					buttons={buttonDesfazer ? buttonDesfazer : []}
					{...showAlert}
				/>
			</IonContent>
		</IonPage>
	)
}

export default AprovacaoOrdemCompra