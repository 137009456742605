import React, { useState, useEffect } from "react";
import './PedidoCon.css';
import {
  IonToast, IonButton
} from '@ionic/react';
import {
  Popover
} from '@material-ui/core';
import ConfigProvider from '../../../assets/utils/configProvider'
import ajax from '../../../assets/utils/ajax';
import EscolherAprovador from './EscolherAprovador';
import LoadingGif from "../../../components/loadingGif/LoadingGif";


interface Registro {
  id: any;
  tipoFinalidade: any;
  idUsuarioAprovacao: any;
  idUsuarioPortalLogado: any;
  idUsuarioPortalPedido: any;
  setPedidoSelecionado: any;
  onCallback: any;
}

const PedidoFinalizar: React.FC<Registro> = (props) => {
  const [showAlert, setShowAlert] = useState<object>();
  const [showPopoverPedirAprovador, setShowPopoverPedirAprovador] = useState(false);
  const [showPopoverPerguntaAprovar, setShowPopoverPerguntaAprovar] = useState(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showLoadingMsg, setShowLoadingMsg] = useState<string>("");


  useEffect(() => {
    if (props.idUsuarioAprovacao != null || props.tipoFinalidade != 1 || props.idUsuarioPortalLogado != props.idUsuarioPortalPedido) {
      props.onCallback(props.id);
      props.setPedidoSelecionado(null);
    } else {
      onPreFinalizarPedido(props.id);
    }

  }, [])

  const onPreFinalizarPedido = (idPedidoExtra: any) => {
    setShowLoadingMsg("Finalizando pedido extra...");

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/cadastro/exists/pedeAprovacaoAprovador',
      data: {
        registro: {
          id: idPedidoExtra
        }
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        if (response.registro && response.registro.pedidoExtraGrupo != null 
          && response.registro.pedidoExtraGrupo.pedeAprovador != null && response.registro.pedidoExtraGrupo.pedeAprovador == 1) {
          setShowPopoverPerguntaAprovar(true);
        } else {
          props.onCallback(idPedidoExtra);
          props.setPedidoSelecionado(null);
        }

        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        if (response.responseCallback) {
          response.responseCallback()
        }
        props.onCallback(idPedidoExtra);
        props.setPedidoSelecionado(null);
      },
      beforeMsgFailure: function (response: any) {
        if (response.responseCallback) {
          response.responseCallback()
        }
        props.onCallback(idPedidoExtra);
        props.setPedidoSelecionado(null);
      }
    });
  }

  return (
    <>

      <LoadingGif show={showLoading} msg={showLoadingMsg} />

      <Popover
        open={showPopoverPerguntaAprovar}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        // mode="ios"
        onClose={e => {
          setShowPopoverPerguntaAprovar(false)
          props.setPedidoSelecionado(null);
        }}
      >
        <div className="myPopover">
          <p>Precisa de aprovador para este pedido?</p>
          <IonButton
            color="success"
            onClick={() => {
              setShowPopoverPerguntaAprovar(false); // fecha essa pergunta
              setShowPopoverPedirAprovador(true); // abre a tela de escolha de aprovador
            }}
            size="small"
          >
            Sim
          </IonButton>
          <IonButton
            color="danger"
            onClick={() => {
              setShowPopoverPerguntaAprovar(false)
              props.onCallback(props.id);
              props.setPedidoSelecionado(null);
            }}
            size="small"
          >
            Não
          </IonButton>
        </div>
      </Popover>

      <EscolherAprovador
        id={props.id}
        idUsuarioAprovacao={props.idUsuarioAprovacao}
        idUsuarioPortal={props.idUsuarioPortalLogado}
        onCallback={props.onCallback}
        setPedidoSelecionado={props.setPedidoSelecionado}
        showPopoverPedirAprovador={showPopoverPedirAprovador}
        setShowPopoverPedirAprovador={setShowPopoverPedirAprovador}
        alteraAprovadorOnFinalizar={true}
      />

      <IonToast
        isOpen={false}
        cssClass='my-custom-class'
        mode='ios'
        {...showAlert}
      />
    </>
  );
};

export default PedidoFinalizar;