import React from "react";
import { IonBadge, IonLabel } from "@ionic/react";
import { format, isToday, isThisWeek } from "date-fns";
import { ptBR } from "date-fns/locale";

interface ShowDateProps {
  date: string;
}

const ShowDate: React.FC<ShowDateProps> = ({ date }) => {
  let formattedDate;
  if (date) {
    const parsedDate = new Date(date);

    if (isToday(parsedDate)) {
      // Se for o dia de hoje, mostra apenas a hora
      formattedDate = format(parsedDate, "HH:mm");
    } else if (isThisWeek(parsedDate, { weekStartsOn: 1 })) {
      // Se for na mesma semana, exibe o dia da semana (ex: "Segunda-feira")
      formattedDate = format(parsedDate, "iiii", { locale: ptBR });
      formattedDate = formattedDate.substring(0, 3).toLowerCase();
    } else {
      // Se não for nem hoje, nem na semana atual, exibe a data completa
      formattedDate = format(parsedDate, "dd/MM/yyyy");
    }
  }

  return (
    <>
      <IonLabel
        color="dark"
        style={{ textAlign: "right", fontWeight: "bold", fontSize: "11px" }}
      >
        {formattedDate}
      </IonLabel>
    </>
  );
};

export default ShowDate;
