import React, { useState, useEffect, useRef } from "react";
import useIsElementVisible from "../hooks/useIsElementVisible";
import {
  IonIcon, IonCard, IonBadge,
  IonGrid, IonRow, IonCol, IonRouterLink, IonButton, IonButtons, IonToolbar
} from '@ionic/react';
import './Card.css';
import {
  removeCircle, arrowUndo, trashSharp, create, chatboxEllipses, attachOutline,
  arrowDown, checkmarkDone, checkmarkOutline, personOutline, sendOutline, copyOutline
} from 'ionicons/icons';
import Tooltip from '@material-ui/core/Tooltip';
import Countdown, { zeroPad } from 'react-countdown';
import ShowMoreButton from '../../../../components/showMoreButton/ShowMoreButton';
import LoadingGif from "../../../../components/loadingGif/LoadingGif";

const moment = require('moment');
const getStatusPedido = (statusPedido: number) => {
  if (statusPedido == 1) return "pendentes";
  if (statusPedido == 2) return "andamentos";
  if (statusPedido == 3) return "concluidos";
  if (statusPedido == 4) return "ignorados";
}

const getUserAcao = (props: any, row: any) => {
  return props.idUsuarioPortal == row.idUsuarioPortal ? "Você"
    : row.idUsuarioPortal != null ? "Portal" : "Comprador";
}


interface CardProps {
  data: any;
  limit: any;
  propsRouter: any;
  setValueSearch: any;
  idUsuarioPortal: any;
  onSetShowPopoverIgnorar: any;
  onSetPedidoSelecionado: any;
  onSetShowPopover: any;
  onSetShowModal: any;
  onSetShowModalChat: any;
  onSetShowModalAnexo: any;
  onSetPedidoChat: any;
  onSetShowPopoverAprovar: any;
  onSetShowPopoverReprovar: any;
  onSetShowPopoverPedirAprovador: any;
  onSetAlteraSomenteObservacao: any;
  onRestabelecerPedido: any;
  onReenviaNotificacaoComprador: any;
  onDuplicarPedido: any;
  onFinish: any;
  onFinalizarPedido: any;
  pedeAprovacao: any;
  pedeAprovador: any;
}

interface Registro {
  id: any,
  tipoFinalidade: any,
  idUsuarioAprovacao: any
}

let page = 1;

const Card: React.FC<CardProps> = (props) => {

  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [disableBtn, setDisableBtn] = useState<boolean>(false);
  const [showAprovar, setShowAprovar] = useState<boolean>(false);
  const lastRef = useRef(null);
  const isLastVisible = useIsElementVisible(lastRef.current);
  const limit = props.limit;
  let totalRowns = 0;

  useEffect(() => {

    if (props.onFinish && isLastVisible) {
      if ((props.data.length / limit) >= (page === 0 ? 1 : page)) {
        onLoadData();
      }
    }

  }, [isLastVisible]);

  useEffect(() => {

    // verifica se o total de registros é menor que a qtd de paginas
    if (Math.ceil(props.data.length / limit) < (page === 0 ? 1 : page)) {
      page = Math.ceil(props.data.length / limit);
      if (page === 0) page = 1;
    }
    setDisableBtn(!((props.data.length / limit) >= (page === 0 ? 1 : page)));

  }, [props.data]);

  const onLoadData = () => {
    page++;
    setShowLoading(true);
    props.onFinish(true, null, page, () => setShowLoading(false));
  }

  return (
    <div>
      <IonGrid className="card-grid">
        <IonRow>
          {props.data.map((row: any, index: number) => {
            totalRowns++
            return (
              <IonCol key={index} sizeXl="4" sizeLg="4" sizeMd="6" sizeSm="12" sizeXs="12">
                <div className="div-finalidade" hidden={row.tipoFinalidade != 2}>
                  <span className="span-finalidade">Cotação</span>
                </div>
                <IonCard className="card-pedido">
                  <IonToolbar className={"card-header-" + getStatusPedido(row.statusPedido)}>
                    <div className="div-descricao-pedido"><span>{row.pedidoExtraTipo.descricao}</span></div>
                  </IonToolbar>

                  <IonRouterLink
                    className="router-link"
                    onClick={(el: any) => {
                      if (el.target.tagName == "ION-BUTTON" || el.target.tagName == "BUTTON") return;
                      props.setValueSearch("");
                      props.propsRouter.history.push("/viewport/pedido/" + row.id + "/produtos");
                    }}
                  >
                    <div className="content-card-pedido">
                      <div className="div-top">
                        <div style={{ textAlign: "center" }}>
                          <IonButton
                            title=""
                            hidden={(row.idUsuarioPortal != null ? row.idUsuarioPortal != props.idUsuarioPortal : false) || row.statusPedido == 3 || row.statusPedido == 4 || row.situacao > 2}
                            disabled={row.situacao > 2 || moment(moment().format("YYYY-MM-DD HH:mm:ss")).isAfter(moment(row.dataTerminoDigitacao))}
                            size="small"
                            className={row.statusPedido == 3 ? 'btn-finalizado-pedido' : 'btn-finalizar-pedido'}
                            onClick={() => {

                              if (row.statusPedido != 3) {
                                props.onSetPedidoSelecionado({
                                  id: row.id,
                                  idUsuarioAprovacao: row.idUsuarioAprovacao,
                                  tipoFinalidade: row.tipoFinalidade,
                                  idUsuarioPortalPedido: row.idUsuarioPortal,
                                  abrirTelaAprovacao: true,
                                  finalizaPedido: true
                                });
                              }
                            }}
                          >
                            {row.statusPedido == 3 ? "FINALIZADO" : "FINALIZAR"}
                            <IonIcon slot="start" icon={row.statusPedido == 3 ? checkmarkDone : checkmarkOutline} />
                          </IonButton>
                        </div>

                        <p className="text-card-center" style={{ fontSize: 11, fontWeight: "bold", color: "blue" }}>
                          {getUserAcao(props, row)} Criou
                        </p>

                        {row.situacao > 1
                          ?
                          <div className="text-card-center" style={{ fontSize: 11, fontWeight: "bold", color: "red" }}>
                            {row.situacao == 2 && row.statusPedido == 3 ? "Aguardando comprador"
                              : row.situacao == 3 ? ("Comprador análisando" + (row.dataHoraSituacao ? (" (" + moment(row.dataHoraSituacao).format("DD-MM-YYYY HH:mm") + ")") : ""))
                                : row.situacao == 5 ? ("Comprador gerou ordem de compra" + (row.dataHoraSituacao ? (" (" + moment(row.dataHoraSituacao).format("DD-MM-YYYY HH:mm") + ")") : ""))
                                  : row.situacao == 6 ? ("Comprador inutilizou" + (row.dataHoraSituacao ? (" (" + moment(row.dataHoraSituacao).format("DD-MM-YYYY HH:mm") + ")") : ""))
                                    : row.situacao == 4 ? ("Comprador concluiu a cotação" + (row.dataHoraSituacao ? (" (" + moment(row.dataHoraSituacao).format("DD-MM-YYYY HH:mm") + ")") : ""))
                                      : row.situacao == 9 ? ("Aguardando Aprovação" + (row.dataHoraSituacao ? (" (" + moment(row.dataHoraSituacao).format("DD-MM-YYYY HH:mm") + ")") : ""))
                                        : row.situacao == 7 ? (" Compra aprovada" + (row.dataHoraSituacao ? (" (" + moment(row.dataHoraSituacao).format("DD-MM-YYYY HH:mm") + ")") : ""))
                                          : row.situacao == 8 ? (" Compra reprovada" + (row.dataHoraSituacao ? (" (" + moment(row.dataHoraSituacao).format("DD-MM-YYYY HH:mm") + ")") : ""))
                                            : ""}
                          </div>
                          :
                          ""
                        }

                        <div className="text-card-center" style={{ fontSize: 11, fontWeight: "bold", color: "red" }}>
                          {row.idUsuarioAprovacao != null && row.situacao > 1 && row.statusPedido == 3 ?
                            row.situacaoAprovacao != null ?
                              row.situacaoAprovacao == 1 ? <><span style={{ fontSize: 12, color: "#6b6363" }}>Aguardando aprovação por <b>{(row.usuarioAprovacao != null ? row.usuarioAprovacao.nome : "")}</b></span><br /></> :
                                row.situacaoAprovacao == 2 ? <><span style={{ fontSize: 12, color: "green" }}>Pedido aprovado por <b>{(row.usuarioAprovacao != null ? row.usuarioAprovacao.nome : "")}</b></span><br /></>
                                  : <><span style={{ fontSize: 12, color: "red" }}>Pedido reprovado por <b>{(row.usuarioAprovacao != null ? row.usuarioAprovacao.nome : "")}</b></span><br /></> :
                              <><span style={{ fontSize: 12, color: "#6b6363" }}>Aguardando aprovação por <b>{(row.usuarioAprovacao != null ? row.usuarioAprovacao.nome : "")}</b></span><br /></>
                            :
                            row.situacao == 4 && row.idUsuarioPortal == props.idUsuarioPortal && row.idTipoPedido == 1 && row.situacaoAprovacaoSolicitante == null ?
                              <>
                                <div hidden={showAprovar || !props.pedeAprovador}>
                                  <span style={{ color: "black" }}>PRECISA DE APROVADOR?</span>
                                  <button className='btn-aprovar-compra' onClick={() => {
                                    props.onSetPedidoSelecionado({
                                      id: row.id,
                                      tipoFinalidade: row.tipoFinalidade,
                                      abrirTelaAprovacao: true,
                                      finalizaPedido: false
                                    });
                                    props.onSetShowPopoverPedirAprovador(true);
                                  }}>Sim</button>

                                  <button className='btn-reprovar-compra' onClick={() => {
                                    setShowAprovar(true);
                                  }}>Não</button>
                                </div>

                                <div hidden={(props.pedeAprovador ? (showAprovar ? (!props.pedeAprovacao || row.situacaoAprovacaoSolicitante != null) : !showAprovar) : (!props.pedeAprovacao || row.situacaoAprovacaoSolicitante != null))}>
                                  <span style={{ color: "black" }}>APROVAR A COMPRA?</span>
                                  <button className='btn-aprovar-compra' onClick={() => {
                                    props.onSetPedidoSelecionado({
                                      id: row.id
                                    });
                                    props.onSetShowPopoverAprovar(true);
                                  }}>Sim</button>

                                  <button className='btn-reprovar-compra' onClick={() => {
                                    props.onSetPedidoSelecionado({
                                      id: row.id
                                    });
                                    props.onSetShowPopoverReprovar(true);
                                  }}>Não</button>
                                </div>
                              </>

                              : row.idUsuarioAprovacao == null && row.idUsuarioPortal == props.idUsuarioPortal && row.situacaoAprovacaoSolicitante != null
                                ? <><span style={{ fontSize: 12, color: (row.situacaoAprovacaoSolicitante == 1 ? "green" : "red") }}>Pedido {row.situacaoAprovacaoSolicitante == 1 ? "aprovado" : "reprovado"} por <b>você</b></span><br /></>

                                : row.idUsuarioAprovacao == null && row.idUsuarioPortal == props.idUsuarioPortal && (row.situacao == 5 || row.situacao == 7 || row.situacao == 8)
                                  ? <><span style={{ fontSize: 12, color: (row.situacao == 7 || row.situacao == 5 ? "green" : "red") }}>Pedido {row.situacao == 7 || row.situacao == 5 ? "aprovado" : "reprovado"} pelo <b>comprador</b></span><br /></>
                                  : ""
                          }
                        </div>

                        <p className="text-card-center">{row.id + " - " + row.descricao}</p>

                        {row.observacao ? <><p className="text-card"><b>Obs. Comprador:</b> <ShowMoreButton title={"Observação - " + row.id} content={row.observacao} limit={20} /></p></> : ""}
                        {row.observacaoPortal ? <><p className="text-card"><b>Sua Obs.:</b> <ShowMoreButton title={"Sua Observação - " + row.id} content={row.observacaoPortal} limit={20} /></p></> : ""}
                      </div>

                      <div className="div-bottom">
                        <p className="text-card-data">
                          <span className="labels-card"><b>Data do Pedido:</b> &nbsp;</span>
                          <span className="result-label-card">{moment(row.dataPedido).format("DD/MM/YYYY")}</span>
                        </p>

                        {(row.statusPedido == 1 || row.statusPedido == 2 || row.statusPedido == 3) && moment(row.dataTerminoDigitacao).isAfter(moment())
                          ?
                          <Countdown
                            key={index}
                            daysInHours={true}
                            date={moment(row.dataTerminoDigitacao).toString()}
                            renderer={({ days, hours, minutes, seconds, completed }) => {
                              if (completed) {
                                setTimeout(function () {
                                  if (props.onFinish) props.onFinish(true, null, page);
                                }, 1000);
                                return "";
                              } else {
                                return (
                                  <p className="text-card-data">
                                    <span className="labels-card"><b>Término dig.</b> &nbsp;</span>
                                    <span className="result-label-card">{days} {days > 1 ? "dias" : "dia"} e {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)} horas</span>
                                  </p>
                                );
                              }
                            }} />
                          :
                          moment(row.dataTerminoDigitacao).isBefore(moment()) ?
                            <p className="text-card-data">
                              <span className="labels-card"><b>Términou em</b> &nbsp;</span>
                              <span className="result-label-card">{moment(row.dataTerminoDigitacao).format("DD/MM/YYYY HH:mm:ss")}</span>
                            </p>
                            : ""}
                      </div>
                    </div>
                  </IonRouterLink>


                  <IonToolbar className={"card-header-buttons"}>

                    <IonButtons slot="start">
                      {row.statusPedido != 4 && !moment(row.dataTerminoDigitacao).isBefore(moment()) && row.situacao == 2
                        ?
                        <Tooltip
                          arrow
                          title={
                            <React.Fragment>
                              <h5 style={{ margin: 2, padding: 0 }}>
                                Ignorar Pedido
                              </h5>
                              <p style={{ margin: 2, padding: 0, lineHeight: "1.2em" }}>
                                Clique aqui para ignorar este pedido.
                              </p>
                            </React.Fragment>
                          }
                        >
                          <IonButton
                            title=""
                            fill="solid"
                            color="light"
                            onClick={() => {
                              props.onSetPedidoSelecionado({
                                idEmpresa: null,
                                idPedidoExtra: row.id
                              });
                              props.onSetShowPopoverIgnorar();
                            }}>
                            <IonIcon
                              icon={removeCircle}
                              color="medium"
                              className="iconsToolbar"
                            />
                          </IonButton>
                        </Tooltip>
                        :
                        row.statusPedido == 4 && !moment(row.dataTerminoDigitacao).isBefore(moment()) && row.situacao == 2
                          ?
                          <Tooltip
                            arrow
                            title={
                              <React.Fragment>
                                <h5 style={{ margin: 2, padding: 0 }}>
                                  Restabelecer Pedido
                                </h5>
                                <p style={{ margin: 2, padding: 0, lineHeight: "1.2em" }}>
                                  Clique aqui para restabelecer este pedido.
                                </p>
                              </React.Fragment>
                            }
                          >
                            <IonButton
                              title=""
                              fill="solid"
                              color="light"
                              onClick={() => {
                                props.onRestabelecerPedido({
                                  idPedidoExtra: row.id
                                });
                              }}>
                              <IonIcon
                                icon={arrowUndo}
                                color="medium"
                                className="iconsToolbar"
                              />
                            </IonButton>
                          </Tooltip>
                          : ""}
                    </IonButtons>

                    <IonButtons slot="end">
                      {(props.pedeAprovador && row.idTipoPedido == 1 && row.idUsuarioPortal == props.idUsuarioPortal
                        && ((row.tipoFinalidade == 1 && (row.situacao == 1 || row.situacao == 2 || row.situacao == 3 || row.situacao == 8 || row.situacao == 9) && row.statusPedido == 3)
                          || (row.tipoFinalidade == 2 && (row.situacao == 4 || row.situacao == 8 || row.situacao == 9) && row.idUsuarioAprovacao != null))) ?
                        <Tooltip
                          arrow
                          title={
                            <React.Fragment>
                              <h5 style={{ margin: 2, padding: 0 }}>
                                {row.idUsuarioAprovacao != null ? "Trocar Aprovador" : "Selecionar Aprovador"}
                              </h5>
                              <p style={{ margin: 2, padding: 0, lineHeight: "1.2em" }}>
                                Clique aqui para selecionar um usuário aprovador.
                              </p>
                            </React.Fragment>
                          }
                        >
                          <IonButton
                            title=""
                            fill="solid"
                            color="light"
                            onClick={() => {
                              props.onSetPedidoSelecionado({
                                id: row.id,
                                abrirTelaAprovacao: true,
                                idUsuarioAprovacao: row.idUsuarioAprovacao,
                                tipoFinalidade: row.tipoFinalidade,
                                finalizarPedido: false
                              });
                              props.onSetShowPopoverPedirAprovador(true);
                            }}>

                            <IonIcon
                              icon={personOutline}
                              color="medium"
                              className="iconsToolbar"
                            />
                          </IonButton>
                        </Tooltip>
                        :
                        ""}

                      {(row.idUsuarioPortal == props.idUsuarioPortal && row.situacao == 2 && row.statusPedido == 3) ?
                        <Tooltip
                          arrow
                          title={
                            <React.Fragment>
                              <h5 style={{ margin: 2, padding: 0 }}>
                                Reenviar notificação ao comprador ou grupo de compradores
                              </h5>
                              <p style={{ margin: 2, padding: 0, lineHeight: "1.2em" }}>
                                Clique aqui para reenviar notificação ao comprador ou grupo de compradores.
                              </p>
                            </React.Fragment>
                          }
                        >
                          <IonButton
                            title=""
                            fill="solid"
                            color="light"
                            onClick={() => {
                              props.onReenviaNotificacaoComprador(row.id);
                            }}>

                            <IonIcon
                              icon={sendOutline}
                              color="medium"
                              className="iconsToolbar"
                            />
                          </IonButton>
                        </Tooltip>
                        :
                        ""}

                      <Tooltip
                        arrow
                        title={
                          <React.Fragment>
                            <h5 style={{ margin: 2, padding: 0 }}>
                              Anexos
                            </h5>
                            <p style={{ margin: 2, padding: 0, lineHeight: "1.2em" }}>
                              Clique aqui para visualizar os anexos.
                            </p>
                          </React.Fragment>
                        }
                      >
                        <IonButton
                          title=""
                          fill="solid"
                          color="light"
                          onClick={() => {
                            props.onSetPedidoSelecionado({
                              id: row.id
                            });
                            props.onSetShowModalAnexo(true);
                          }}>

                          <IonIcon
                            icon={attachOutline}
                            color="medium"
                            className="iconsToolbar"
                          />
                        </IonButton>
                      </Tooltip>

                      <Tooltip
                        arrow
                        title={
                          <React.Fragment>
                            <h5 style={{ margin: 2, padding: 0 }}>
                              Abrir mensagens
                            </h5>
                            <p style={{ margin: 2, padding: 0, lineHeight: "1.2em" }}>
                              Clique aqui para exibir as mensagens deste pedido.
                            </p>
                          </React.Fragment>
                        }
                      >
                        <IonButton
                          title=""
                          fill="solid"
                          color="light"
                          onClick={() => {
                            props.onSetPedidoChat({
                              id: row.id,
                              descricao: row.descricao,
                              pedidoDisabled: !(props.idUsuarioPortal == row.idUsuarioPortal && row.situacao == 2)
                            });
                            props.onSetShowModalChat(true);
                          }}>
                          <IonIcon
                            icon={chatboxEllipses}
                            color="medium"
                            className="iconsToolbar"
                          />
                          <IonBadge
                            hidden={!(row.mensagensNovas != null && row.mensagensNovas > 0)}
                            onClick={() => {
                              props.onSetPedidoChat({
                                id: row.id,
                                descricao: row.descricao,
                                pedidoDisabled: !(props.idUsuarioPortal == row.idUsuarioPortal && row.situacao == 2)
                              });
                              props.onSetShowModalChat(true);
                            }}
                            className="badge-btn-notification"
                            color="success">
                            {row.mensagensNovas}
                          </IonBadge>
                        </IonButton>
                      </Tooltip>


                      <Tooltip
                        arrow
                        title={
                          <React.Fragment>
                            <h5 style={{ margin: 2, padding: 0 }}>
                              Duplicar pedido extra para o seu usuário
                            </h5>
                            <p style={{ margin: 2, padding: 0, lineHeight: "1.2em" }}>
                              Clique aqui para duplicar o pedido para o seu usuário.
                            </p>
                          </React.Fragment>
                        }
                      >
                        <IonButton
                          title=""
                          fill="solid"
                          color="light"
                          onClick={() => {
                            props.onDuplicarPedido(row.id);
                          }}>

                          <IonIcon
                            icon={copyOutline}
                            color="medium"
                            className="iconsToolbar"
                          />
                        </IonButton>
                      </Tooltip>

                      <Tooltip
                        arrow
                        title={
                          <React.Fragment>
                            <h5 style={{ margin: 2, padding: 0 }}>
                              Editar Pedido
                            </h5>
                            <p style={{ margin: 2, padding: 0, lineHeight: "1.2em" }}>
                              Clique aqui para editar este pedido.
                            </p>
                          </React.Fragment>
                        }
                      >
                        <IonButton
                          title=""
                          fill="solid"
                          color="light"
                          hidden={!((props.idUsuarioPortal == row.idUsuarioPortal || row.idUsuarioPortal == null))}
                          onClick={() => {
                            props.onSetPedidoSelecionado({
                              id: row.id
                            });
                            props.onSetAlteraSomenteObservacao(props.idUsuarioPortal != row.idUsuarioPortal);
                            props.onSetShowModal(true);
                          }}>
                          <IonIcon
                            icon={create}
                            color="medium"
                            className="iconsToolbar"
                          />
                        </IonButton>
                      </Tooltip>

                      <Tooltip
                        arrow
                        title={
                          <React.Fragment>
                            <h5 style={{ margin: 2, padding: 0 }}>
                              Remover Pedido
                            </h5>
                            <p style={{ margin: 2, padding: 0, lineHeight: "1.2em" }}>
                              Clique aqui para remover este pedido.
                            </p>
                          </React.Fragment>
                        }
                      >
                        <IonButton
                          title=""
                          fill="solid"
                          color="light"
                          hidden={!(props.idUsuarioPortal == row.idUsuarioPortal && row.situacao == 2)}
                          onClick={() => {
                            props.onSetPedidoSelecionado({
                              id: row.id
                            });
                            props.onSetShowPopover(true);
                          }}>
                          <IonIcon
                            icon={trashSharp}
                            color="medium"
                            className="iconsToolbar"
                          />
                        </IonButton>
                      </Tooltip>

                    </IonButtons>
                  </IonToolbar>
                </IonCard>

              </IonCol>
            )
          })}
        </IonRow>

        <LoadingGif show={showLoading} msg={"Carregando mais registros..."} />

      </IonGrid>

      <div style={{ textAlign: "center" }}>
        <IonButton
          title=""
          disabled={disableBtn}
          shape="round"
          color="medium"
          size="small"
          onClick={onLoadData}
        >
          Mostrar Mais
          <IonIcon slot="end" icon={arrowDown}></IonIcon>
        </IonButton>
      </div>

      {totalRowns == props.data.length ? <div ref={lastRef} /> : ""}
    </div >
  );
};

export default Card;
