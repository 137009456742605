import React, { useEffect, useState } from 'react';
import {
  IonHeader, IonContent, IonToolbar, IonButtons, IonToast, IonTitle,
  IonGrid, IonRow, IonCol, IonModal, IonImg
} from '@ionic/react';
import { DropzoneArea } from 'material-ui-dropzone';
import './AnexoProdutoCon.css';
import FilterContainer from '../../../../../components/filterContainer/filterContainer';
import FilterItem from '../../../../../components/filterContainer/filterItem';
import ajax from '../../../../../assets/utils/ajax';
import ConfigProvider from '../../../../../assets/utils/configProvider';
import {
  G3FecharEfeitoIcon
} from '../../../../../assets/utils/g3icons';
import CardAnexo from '../../anexo/cardAnexo/CardAnexo';
import { Plugins } from '@capacitor/core';
import LoadingGif from '../../../../../components/loadingGif/LoadingGif';

const { SecureStoragePlugin } = Plugins;
const moment = require('moment');


interface AnexoProdutoConProps {
  idProdutoExtra: any;
  idUsuarioPortal: any;
  propsRouter: any;
  onSetShowModalAnexo: any;
  disableSave: boolean;
}

interface Registro {
  id: any;
  idProdutoExtra: any;
  idUsuario: any;
  idUsuarioPortal: any;
  nome: any;
  extensao: any;
  arquivo: any;
  dataHoraGravacao: any;
}

let tok: any = null;
const msgLoadingDeafult = "Carregando anexos...";

const AnexoProdutoCon: React.FC<AnexoProdutoConProps> = (props) => {
  const [showModalPreview, setShowModalPreview] = React.useState(false);
  const [imagemSelecionada, setImagemSelecionada] = React.useState<string>();
  const [fileObjects, setFileObjects] = useState<File[]>([]);
  const [showAlert, setShowAlert] = useState<object>();
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [showLoadingMsg, setShowLoadingMsg] = useState<string>(msgLoadingDeafult);
  const [registros, setRegistros] = useState<Registro[]>([]);
  const [pedidoDisabled, setPedidoDisabled] = useState(false);

  useEffect(() => {
    findAnexos();
  }, [props.idProdutoExtra]);

  const findAnexos = () => {
    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/produtos/anexos/cadastro/load/app',
      data: {
        registro: {
          idProdutoExtra: props.idProdutoExtra
        }
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        const regs = response.registros;
        const isDisabled = !regs || regs.length == 0 || regs[0].pedidoExtra.situacao > 2 || moment(moment().format("YYYY-MM-DD")).isAfter(moment(regs[0].pedidoExtra.dataTerminoDigitacao));
        setPedidoDisabled(isDisabled);

        setRegistros(regs);

        if (response.responseCallback) {
          response.responseCallback()
        }
      }
    });
  }

  const saveAnexo = (registroAux: any, callback: any) => {
    try {
      ajax({
        url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/produtos/anexos/cadastro/save/app',
        data: registroAux,
        multiPartFormData: true,
        headers: [
          { key: 'token', value: tok ? tok.value : '' },
          { key: 'idRegistro', value: props.idProdutoExtra },
          { key: 'tipoAplicacao', value: new ConfigProvider().getConfig("tipoAplicacao") }
        ],
        fnSetShowAlert: setShowAlert,
        beforeMsgSuccessTrue: function (response: any) {
          if (response && response.responseCallback) {
            response.responseCallback()
          }
          if (callback) callback(true);
        },
        beforeMsgSuccessFalse: function (response: any) {
          if (response && response.responseCallback) {
            response.responseCallback()
          }
          if (callback) callback(false);
        },
        beforeMsgSuccessFailure: function (response: any) {
          if (response && response.responseCallback) {
            response.responseCallback()
          }
          if (callback) callback(false);
        }
      });
    }
    catch (exception) {
      if (callback) callback(false);
    }
  }

  const onRemoveAnexo = (id: any, idProdutoExtra: any) => {
    if (id === null) return;

    if (props.disableSave) {
      setShowAlert({
        isOpen: true,
        duration: 4000,
        color: 'danger',
        header: 'Aviso',
        message: "Somente o criador do pedido tem permissão para alterar os anexos",
        buttons: ['OK'],
        onDidDismiss: () => {
          setShowAlert({ isOpen: false });
        }
      });
      return;
    }

    setShowLoadingMsg("Removendo anexo...");

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/produtos/anexos/cadastro/delete/app',
      data: {
        registro: {
          id: id,
          idProdutoExtra: idProdutoExtra
        }
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
        setShowAlert({
          isOpen: true,
          duration: 4000,
          color: 'success',
          header: 'Sucesso',
          message: "Anexo removido com sucesso!",
          buttons: ['OK'],
          onDidDismiss: () => {
            setShowAlert({ isOpen: false });
          }
        });

        findAnexos();

        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      },
      beforeMsgSuccessFailure: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      }
    });
  }

  const upload = async (files: any) => {

    if (props.disableSave) {
      setShowAlert({
        isOpen: true,
        duration: 4000,
        color: 'danger',
        header: 'Aviso',
        message: "Somente o criador do pedido tem permissão para alterar os anexos",
        buttons: ['OK'],
        onDidDismiss: () => {
          setShowAlert({ isOpen: false });
        }
      });
      return;
    }

    tok = await SecureStoragePlugin.get({ key: 'token' })
      .then((value: any) => {
        return value;
      })
      .catch((error: any) => null);

    setShowLoadingMsg("Salvando anexos...");
    setShowLoading(true);

    for (const file of files) {
      await requestUpload(file);
    }

    setShowLoading(false);
    setShowLoadingMsg(msgLoadingDeafult);
    findAnexos();
  }

  const requestUpload = async (file: any) => {
    return new Promise((resolve, reject) => {
      const ext = file.name.slice((Math.max(0, file.name.lastIndexOf(".")) || Infinity) + 1);

      var data = new FormData();
      data.append("fileId", "1");
      data.append("fileName", file.name);
      data.append("fileExtension", ext);
      data.append("fileSize", file.size);
      data.append("fileType", file.type);
      data.append("fileData", file);

      saveAnexo(data, (success: boolean) => {
        resolve(success);
      });
    });
  }

  return (
    <>
      <IonHeader className='ionGridViewport-border-red'>
        <IonGrid className="ionGridViewport">
          <IonRow>
            <IonCol className="ionColViewport" size={"4"}>
              <IonToolbar>
                <IonButtons slot="start">
                </IonButtons>
              </IonToolbar>
            </IonCol>

            <IonCol className="ionColViewport" style={{ height: "inherit" }} size={"4"}>
              <IonToolbar>
                <IonTitle className='titleCenterWhite'>Anexos</IonTitle>
              </IonToolbar>
            </IonCol>

            <IonCol style={{ height: "inherit" }} className="ionColViewport">
              <IonToolbar>
                <IonButtons slot="end">
                  <FilterContainer>
                    <FilterItem
                      iconType
                      onClick={() => {
                        props.onSetShowModalAnexo(false)
                      }}
                      icon={<><div style={{ marginLeft: '8px', marginRight: '8px' }}><G3FecharEfeitoIcon className="myIcon" /></div></>}
                      colorText="#404237"
                      padding={0}
                      tolltipTitle={'Ação: Fechar tela'}
                    />
                  </FilterContainer>
                </IonButtons>
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>

      <IonContent>
        <IonToast
          isOpen={false}
          cssClass='my-custom-class'
          mode='ios'
          {...showAlert}
        />

        <LoadingGif show={showLoading} msg={showLoadingMsg} />

        <DropzoneArea
          acceptedFiles={['.png,.jpg,.jpeg,.ogg,.mp3,.oga,.webm,.avi,.mp4,.m4v,.mov,.mpg,.mpeg,.wmv,.pdf,.txt,.doc,.csv,.xls,.xlsx,.docx,.zip,.7z']}
          showPreviewsInDropzone={false}
          showAlerts={false}
          filesLimit={100}
          maxFileSize={20000000} // bytes - 20mb
          dropzoneText={"Arraste e solte aqui ou clique"}
          onChange={(files) => {
            let differences = files.filter(x => !fileObjects.includes(x));
            setFileObjects(fileObjects.concat(differences));
            if (differences.length > 0) upload(differences);
          }}
          getFileLimitExceedMessage={(filesLimit) => {
            setShowAlert({
              isOpen: true,
              duration: 4000,
              color: 'danger',
              header: 'Aviso',
              message: "Número máximo permitido de arquivos excedido. Apenas " + filesLimit + " permitido",
              buttons: ['OK'],
              onDidDismiss: () => {
                setShowAlert({ isOpen: false });
              }
            });
            return ""
          }}
          getDropRejectMessage={(rejectedFile) => {
            let msg = "O arquivo " + rejectedFile.name + " foi rejeitado.";

            if (rejectedFile.size > 20000000) {
              msg += " Tamanho do arquivo muito grande. Tamanho deve ser de até 20MB";
            }
            else {
              msg += " Tipo de arquivo não suportado.";
            }

            setShowAlert({
              isOpen: true,
              duration: 4000,
              color: 'danger',
              header: 'Aviso',
              message: msg,
              buttons: ['OK'],
              onDidDismiss: () => {
                setShowAlert({ isOpen: false });
              }
            });
            return ""
          }}
        />

        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: 13 }}>Extensões permitidas: {'.png, .jpg, .jpeg, .ogg, .mp3, .oga, .webm, .avi, .mp4, .m4v, .mov, .mpg, .mpeg, .wmv, .pdf, .txt, .doc, .csv, .xls, .xlsx, .docx, .zip, .7z'}</span>
        </div>

        {!showLoading ?
          <CardAnexo
            files={registros}
            idProdutoExtra={props.idProdutoExtra}
            idUsuarioPortal={props.idUsuarioPortal}
            pedidoDisabled={pedidoDisabled}
            onSetImagemSelecionada={setImagemSelecionada}
            onSetShowModalPreview={setShowModalPreview}
            onRemoveAnexo={onRemoveAnexo}
          />
          : ""}

        <IonModal
          cssClass="modalProCad"
          backdropDismiss={false}
          swipeToClose={false}
          isOpen={showModalPreview}
          onDidDismiss={() => setShowModalPreview(false)}
        >
          <IonHeader className='ionGridViewport-border-red'>
            <IonGrid className="ionGridViewport">
              <IonRow>
                <IonCol className="ionColViewport" size={"4"}>
                  <IonToolbar>
                    <IonButtons slot="start">
                    </IonButtons>
                  </IonToolbar>
                </IonCol>

                <IonCol className="ionColViewport" style={{ height: "inherit" }} size={"4"}>
                  <IonToolbar>
                  </IonToolbar>
                </IonCol>

                <IonCol style={{ height: "inherit" }} className="ionColViewport">
                  <IonToolbar>
                    <IonButtons slot="end">
                      <FilterContainer>
                        <FilterItem
                          iconType
                          onClick={() => {
                            setShowModalPreview(false)
                          }}
                          icon={<><div style={{ marginLeft: '8px', marginRight: '8px' }}><G3FecharEfeitoIcon className="myIcon" /></div></>}
                          colorText="#404237"
                          padding={0}
                          tolltipTitle={'Ação: Fechar tela'}
                        />
                      </FilterContainer>
                    </IonButtons>
                  </IonToolbar>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonHeader>

          <IonContent>
            <IonImg className="anexo-img" src={imagemSelecionada} />
          </IonContent>
        </IonModal>
      </IonContent>
    </>
  );
};

export default AnexoProdutoCon;